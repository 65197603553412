define('checkmate/mixins/components/input-filter-unavailable-symbols-mixin', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var NOT_ALLOWED_SYMBOLS_REGEXP = /[,/\\]/g;

	exports.default = Ember.Mixin.create({
		actions: {
			filterUnavailableSymbols: function filterUnavailableSymbols(model, key) {
				var currentString = model.get(key);
				var updatedString = currentString.replace(NOT_ALLOWED_SYMBOLS_REGEXP, '');
				model.set(key, updatedString);
			}
		}
	});
});