define('checkmate/enums/reportGenerationModalHeaders', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		PROGRESS_STATUS: 'Progress Status',
		SYSTEM_ERROR: 'System Error',
		SUCCESSFULLY_GENERATED: 'Successfully Generated'
	});
});