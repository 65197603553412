define('checkmate/mirage/factories/merchant', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		fileId: function fileId(i) {
			return '' + i;
		},

		merchantNumber: function merchantNumber() {
			return _faker.default.random.number({ min: 1000000000, max: 9999999999 }).toString();
		},
		dbaAddressLine1: _faker.default.address.streetAddress,
		dbaAddressLine2: _faker.default.address.secondaryAddress,
		dbaCity: _faker.default.address.city,
		dbaState: _faker.default.address.state,
		dbaZip: _faker.default.address.zipCode,
		dbaName: _faker.default.company.companyName,
		firstName: _faker.default.name.firstName,
		lastName: _faker.default.name.lastName,
		createdAt: function createdAt() {
			return _faker.default.date.past().toISOString();
		},

		createdBy: _faker.default.name.findName,
		hasReport: _faker.default.random.boolean
	});
});