define('checkmate/services/countries', ['exports', 'checkmate/enums/countries'], function (exports, _countries) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		countries: [],
		fetch: function fetch() {
			this.set('countries', _countries.default);
		},

		countryCodes: Ember.computed('countries.@each.alpha2Code', function () {
			return this.countries.mapBy('alpha2Code');
		})
	});
});