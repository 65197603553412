define('checkmate/routes/reports/new', ['exports', 'checkmate/mixins/routes/reports/listing', 'checkmate/enums/ownerTypes', 'checkmate/enums/reportTypes'], function (exports, _listing, _ownerTypes, _reportTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_listing.default, {
		model: function model() {
			var owner = this.store.createRecord('owner', { type: _ownerTypes.default.PRINCIPAL });
			var merchantDetails = this.store.createRecord('merchant-details');

			merchantDetails.owners.addObject(owner);

			return Ember.RSVP.hash({
				reportType: _reportTypes.default.CUSTOM.toString(),
				merchantDetails: merchantDetails,
				reports: [],
				services: this.store.query('service', { filters: { isActive: true } })
			});
		}
	});
});