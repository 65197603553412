define('checkmate/enums/services', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		GOOGLE_REVIEW: 'google-review',
		UPS: 'ups',
		TMF: 'tmf',
		GOOGLE_SEARCH: 'google-search',
		GOOGLE_STREET_VIEW: 'google-street',
		YELP: 'yelp',
		FEDEDIRECTORY: 'fedach',
		BBB: 'bbb',
		DNB: 'dnb',
		TINCHECK: 'tincheck',
		GAUTH: 'giact-gauthenticate',
		GIDENTIFY_BUSINESS: 'giact-gidentify-business',
		GIDENTIFY_CONSUMER: 'giact-gidentify-person',
		GOFAC: 'giact-gofac',
		BUSINESS_LICENSE: 'business-license',
		MELISSADATA: 'melissa',
		IRS: 'irs',
		WHOIS: 'whois',
		PROHIBITED_PHRASES: 'prohibited-words',
		REVIEW_WEBSITE: 'google-component',
		EXPERIAN: 'experian'
	});
});