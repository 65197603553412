define('checkmate/helpers/replace', ['exports', '@abcum/ember-helpers/helpers/replace'], function (exports, _replace) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _replace.default;
    }
  });
  Object.defineProperty(exports, 'replace', {
    enumerable: true,
    get: function () {
      return _replace.replace;
    }
  });
});