define('checkmate/helpers/lcwords', ['exports', '@abcum/ember-helpers/helpers/lcwords'], function (exports, _lcwords) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _lcwords.default;
    }
  });
  Object.defineProperty(exports, 'lcwords', {
    enumerable: true,
    get: function () {
      return _lcwords.lcwords;
    }
  });
});