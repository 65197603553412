define('checkmate/components/service-list', ['exports', 'checkmate/mappings/business-type-to-services-mapping'], function (exports, _businessTypeToServicesMapping) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed,
	    observer = Ember.observer;
	exports.default = Ember.Component.extend({
		selectableServices: computed('services.[]', function () {
			var _this = this;

			return this.services.map(function (serviceItem) {
				return _this.createItemProxy(serviceItem, false);
			});
		}),
		selectedServices: computed('selectableServices.@each.isChecked', function () {
			return this.selectableServices.filterBy('isChecked', true).mapBy('name');
		}),
		onSelectedServiceChange: observer('merchantServices', function () {
			this.selectServices(this.get('merchantServices'));
		}),
		onBusinessTypeChange: observer('businessType', function () {
			var _this2 = this;

			var businessType = this.get('businessType');

			if (businessType) {
				var businessTypeServices = _businessTypeToServicesMapping.default[businessType];
				this.selectServices(businessTypeServices);
				Ember.run.once(function () {
					return _this2.sendAction('updateSelectedServices', _this2.get('selectedServices'), businessType);
				});
			}
		}),
		createItemProxy: function createItemProxy(item, checked) {
			return Ember.ObjectProxy.create({
				content: item,
				isChecked: checked
			});
		},
		enableServices: function enableServices(servicesList) {
			this.get('selectableServices').filter(function (serviceItem) {
				return servicesList.includes(serviceItem.get('name'));
			}).setEach('isChecked', true);
		},
		disableServices: function disableServices(servicesList) {
			this.get('selectableServices').filter(function (serviceItem) {
				return !servicesList.includes(serviceItem.get('name'));
			}).setEach('isChecked', false);
		},
		selectServices: function selectServices(servicesList) {
			this.enableServices(servicesList);
			this.disableServices(servicesList);
		},

		actions: {
			toggleService: function toggleService(service) {
				service.toggleProperty('isChecked');
				this.sendAction('updateSelectedServices', this.get('selectedServices'));
			}
		}
	});
});