define('checkmate/serializers/report', ['exports', 'checkmate/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			services: { embedded: 'always' },
			merchantDetails: { embedded: 'always' }
		}
	});
});