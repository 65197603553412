define('checkmate/helpers/split', ['exports', '@abcum/ember-helpers/helpers/split'], function (exports, _split) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _split.default;
    }
  });
  Object.defineProperty(exports, 'split', {
    enumerable: true,
    get: function () {
      return _split.split;
    }
  });
});