define('checkmate/services/oauth', ['exports', 'checkmate/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var resolve = Ember.RSVP.resolve;
	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		session: Ember.inject.service(),
		defaultRoute: '/',
		login: function login(code) {
			return this.ajax.post(_environment.default.loginUrl, {
				data: {
					code: code
				}
			});
		},
		authenticate: function authenticate(transition) {
			var _this = this;

			var loginPromise = transition.queryParams.code ? this.login(transition.queryParams.code) : resolve({});

			return loginPromise.then(function (_ref) {
				var token = _ref.token;
				return _this.get('session').authenticate('authenticator:oauth2', token);
			}).then(function () {
				return transition.queryParams.attempt ? transition.queryParams.attempt : _this.defaultRoute;
			});
		},
		model: function model() {
			var transitionAttempt = this.get('session.attemptedTransition.intent.url');
			var queryParams = transitionAttempt ? '?attempt=' + encodeURIComponent(transitionAttempt) : '';
			var callback = encodeURIComponent(window.location.origin + '/#/sign-in' + queryParams);

			return Ember.RSVP.hash({
				authUrl: this.get('ajax').request(_environment.default.googleAuthUrl + '?callback=' + callback).then(function (_ref2) {
					var url = _ref2.url;
					return url;
				})
			});
		}
	});
});