define('checkmate/router', ['exports', 'checkmate/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Router = Ember.Router.extend(_emberRouterScroll.default, {
		location: _environment.default.locationType,
		rootURL: _environment.default.rootURL
	});

	Router.map(function () {
		this.route('sign-out');
		this.route('sign-in');

		this.route('reports', function () {
			this.route('merchant-reports');
			this.route('custom-reports');
			this.route('new');
			this.route('edit', {
				path: 'edit/:id/reportType/:reportType'
			});
		});
	});

	exports.default = Router;
});