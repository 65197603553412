define('checkmate/enums/validationOptions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var ADDRESS_LINE_REGEX = /^[^,\\/]*$/;
	var ADDRESS_LINE_VALIDATION_MESSAGE = 'Address line do not allow symbols except spaces, numbers and letters';

	exports.default = Object.freeze({
		addressLineValidation: {
			regex: ADDRESS_LINE_REGEX,
			message: ADDRESS_LINE_VALIDATION_MESSAGE
		},
		cityValidation: {
			regex: ADDRESS_LINE_REGEX,
			message: ADDRESS_LINE_VALIDATION_MESSAGE
		}
	});
});