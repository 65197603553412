define('checkmate/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/base', 'checkmate/config/environment'], function (exports, _base, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var _Ember$RSVP = Ember.RSVP,
	    resolve = _Ember$RSVP.resolve,
	    reject = _Ember$RSVP.reject;
	exports.default = _base.default.extend({
		ajax: Ember.inject.service(),
		restore: function restore(_ref) {
			var token = _ref.token;

			return token ? resolve({ token: token }) : reject();
		},
		authenticate: function authenticate(token) {
			return token ? resolve({ token: token }) : reject();
		},
		invalidate: function invalidate() {
			return this.ajax.post(_environment.default.logoutUrl).catch(function () {
				return resolve();
			});
		}
	});
});