define('checkmate/helpers/pattern-letters', ['exports', '@abcum/ember-helpers/helpers/pattern-letters'], function (exports, _patternLetters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _patternLetters.default;
    }
  });
  Object.defineProperty(exports, 'patternLetters', {
    enumerable: true,
    get: function () {
      return _patternLetters.patternLetters;
    }
  });
});