define('checkmate/controllers/reports/custom-reports', ['exports', 'checkmate/mixins/controllers/reports/listing'], function (exports, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var QUERY_PARAMS = ['page', 'pageSize', 'searchText', 'createdBy'];
	var TABLE_COLUMNS = [{
		name: 'Business Name',
		attribute: 'merchantDetails.dbaName',
		className: 'three'
	}, {
		name: 'Legal Name',
		attribute: 'merchantDetails.legalName',
		className: 'three'
	}, {
		name: 'Owner',
		attribute: 'merchantDetails.owners.firstObject.fullName',
		className: 'two'
	}, {
		name: 'Address',
		attribute: 'merchantDetails.fullAddress',
		className: 'four'
	}, {
		name: 'Data Entry User',
		attribute: 'merchantDetails.createdBy',
		className: 'two'
	}, {
		name: 'Last Update',
		attribute: 'createdAt',
		template: 'date-time',
		className: 'two'
	}];

	exports.default = Ember.Controller.extend(_listing.default, {
		items: Ember.computed.alias('model.reports'),
		isContentLoading: Ember.computed.alias('items.isUpdating'),
		user: Ember.inject.service(),
		columns: TABLE_COLUMNS,
		filters: Ember.computed.apply(undefined, QUERY_PARAMS.concat([function () {
			return [{
				type: 'textbox',
				placeholder: 'Search Business Name or Legal Name',
				property: 'searchText',
				value: this.get('searchText')
			}, {
				type: 'checkbox',
				placeholder: 'My Reports',
				property: 'createdBy',
				value: this.get('user.userName'),
				checked: !!this.get('createdBy')
			}];
		}]))
	});
});