define('checkmate/components/input-field', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;


	var WARNING_POPUP_CLASS = 'warning';
	var ERROR_POPUP_CLASS = 'error';

	exports.default = Ember.Component.extend({
		tagName: '',
		description: '',
		disabled: false,
		placeholder: null,
		focused: false,
		highlightError: computed.alias('validation.isInvalid'),
		highlightWarning: computed.alias('validation.hasWarnings'),
		popupClass: computed('validation.{isInvalid,hasWarnings}', function () {
			if (this.highlightError) {
				return ERROR_POPUP_CLASS;
			}

			if (this.highlightWarning) {
				return WARNING_POPUP_CLASS;
			}

			return null;
		}),
		popupContent: computed('validation.{isInvalid,hasWarnings}', function () {
			if (this.highlightError) {
				return this.get('validation.message');
			}

			if (this.highlightWarning) {
				return this.get('validation.warningMessage');
			}

			return null;
		}),
		actions: {
			onKeyPress: function onKeyPress() {
				if (this.get('onKeyPress')) {
					this.sendAction('onKeyPress');
				}
			},
			input: function input() {
				if (this.get('onInput')) {
					this.sendAction('onInput');
				}
			}
		}
	});
});