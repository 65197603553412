define('checkmate/adapters/report-status', ['exports', 'checkmate/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForFindRecord: function urlForFindRecord(id) {
			return this.host + '/' + this.namespace + '/reports/' + id + '/status';
		}
	});
});