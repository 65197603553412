define('checkmate/enums/uploadTypes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		FILE_CABINET: 'documentCabinet',
		S3_BUCKET: 's3Bucket'
	});
});