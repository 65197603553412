define('checkmate/enums/serviceStatuses', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		DONE: 'Done',
		IN_PROGRESS: 'In Progress',
		ERROR: 'Cannot be completed',
		PENDING: 'Pending'
	});
});