define('checkmate/helpers/drag-get', ['exports', '@abcum/ember-helpers/helpers/drag-get'], function (exports, _dragGet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dragGet.default;
    }
  });
  Object.defineProperty(exports, 'dragGet', {
    enumerable: true,
    get: function () {
      return _dragGet.dragGet;
    }
  });
});