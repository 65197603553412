define('checkmate/mirage/factories/owner', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		addressLine: _faker.default.address.streetAddress,
		city: _faker.default.address.city,
		firstName: _faker.default.name.firstName,
		lastName: _faker.default.name.lastName,
		phoneNumber: function phoneNumber() {
			return _faker.default.phone.phoneNumber();
		},
		ssn: function ssn() {
			return _faker.default.random.number();
		},
		state: _faker.default.address.state,
		zipCode: function zipCode() {
			return _faker.default.address.zipCode();
		},
		type: function type(i) {
			return i === 0 ? 'manager' : 'principal';
		}
	});
});