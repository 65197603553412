define('checkmate/mirage/routes/reports', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/reports', function (_ref, _ref2) {
			var reports = _ref.reports;
			var queryParams = _ref2.queryParams;
			var _queryParams$metaPag = queryParams['meta[page]'],
			    page = _queryParams$metaPag === undefined ? DEFAULT_PAGE : _queryParams$metaPag,
			    _queryParams$metaPag2 = queryParams['meta[pageSize]'],
			    pageSize = _queryParams$metaPag2 === undefined ? DEFAULT_PAGE_SIZE : _queryParams$metaPag2,
			    searchText = queryParams['filters[searchText]'],
			    createdBy = queryParams['filters[createdBy]'],
			    fileId = queryParams['filters[fileId]'];

			var startIndex = (page - 1) * pageSize;
			var endIndex = page * pageSize;
			var allReports = this.serialize(reports.all()).reports.filter(function (item) {
				return !searchText || item.merchantDetails.dbaName.includes(searchText) || item.merchantDetails.legalName.includes(searchText);
			}).filter(function (item) {
				return !fileId || !item.merchantDetails || item.merchantDetails.fileId === fileId;
			}).filter(function (item) {
				return !createdBy || item.merchantDetails.createdBy === createdBy;
			}).sort(function (a, b) {
				return a.createdAt > b.createdAt;
			});

			return {
				reports: allReports.slice(startIndex, endIndex),
				meta: {
					page: page,
					pageSize: pageSize,
					totalPages: Math.ceil(reports.all().length / pageSize)
				}
			};
		});

		server.get('/reports/:id');

		server.get('/reports/:id/status', function (_ref3, _ref4) {
			var reportStatuses = _ref3.reportStatuses;
			var params = _ref4.params;
			var id = params.id;


			return reportStatuses.find(id);
		});

		server.post('/reports', function (_ref5, _ref6) {
			var reports = _ref5.reports;
			var requestBody = _ref6.requestBody;

			var reportToCreate = JSON.parse(requestBody);

			return reports.create(reportToCreate);
		});
	};

	var DEFAULT_PAGE = 1;
	var DEFAULT_PAGE_SIZE = 10;

	/**
  * Configure endpoints for handling report entity
  *
  * @param {Object} server The mirage server
  */
});