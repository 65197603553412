define('checkmate/initializers/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var inflector = _emberInflector.default.inflector;


  /**
   * Add custom inflector rules for Ember
   */
  function initialize() /* application */{
    inflector.irregular('details', 'details');
  }

  exports.default = {
    initialize: initialize
  };
});