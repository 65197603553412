define('checkmate/models/custom-report', ['exports', 'ember-data', 'checkmate/enums/reportTypes'], function (exports, _emberData, _reportTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		reportType: _reportTypes.default.CUSTOM,
		createdAt: _emberData.default.attr('date'),
		merchantDetails: _emberData.default.belongsTo('merchant-details')
	});
});