define('checkmate/helpers/reload', ['exports', '@abcum/ember-helpers/helpers/reload'], function (exports, _reload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reload.default;
    }
  });
  Object.defineProperty(exports, 'reload', {
    enumerable: true,
    get: function () {
      return _reload.reload;
    }
  });
});