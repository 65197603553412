define('checkmate/adapters/merchant-report', ['exports', 'checkmate/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForQuery: function urlForQuery() {
			return this.host + '/' + this.namespace + '/merchants';
		}
	});
});