define('checkmate/validators/gidentify-consumer-field-value-presence', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var GidentifyConsumerFieldValuePresence = _base.default.extend({
		errorMessage: 'This field can\'t be blank',
		validate: function validate(value, options, model) {
			var sources = model.sources || model.merchantDetails && model.merchantDetails.sources;
			var isServiceSelected = sources && sources.includes(options.service);
			var ownerIndex = model.get('merchantDetails.owners').indexOf(model);
			var shouldValidateOwner = options.ownerIndexesToValidate.includes(ownerIndex);
			var isInvalidValue = !value || !value.trim();
			var hasError = isServiceSelected && shouldValidateOwner && isInvalidValue;

			if (hasError) {
				return options.message || this.errorMessage;
			}

			return true;
		}
	});

	GidentifyConsumerFieldValuePresence.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.merchantDetails.sources'];
		}
	});

	exports.default = GidentifyConsumerFieldValuePresence;
});