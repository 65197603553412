define('checkmate/mappings/business-type-to-services-mapping', ['exports', 'checkmate/enums/services'], function (exports, _services) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		'retail': [_services.default.UPS, _services.default.TINCHECK, _services.default.TMF, _services.default.FEDEDIRECTORY, _services.default.GOOGLE_STREET_VIEW, _services.default.GAUTH, _services.default.GIDENTIFY_BUSINESS, _services.default.GIDENTIFY_CONSUMER, _services.default.GOFAC, _services.default.GOOGLE_SEARCH, _services.default.BBB, _services.default.BUSINESS_LICENSE, _services.default.DNB, _services.default.YELP, _services.default.GOOGLE_REVIEW],
		'ecommerce': [_services.default.UPS, _services.default.WHOIS, _services.default.TINCHECK, _services.default.TMF, _services.default.FEDEDIRECTORY, _services.default.GOOGLE_STREET_VIEW, _services.default.GAUTH, _services.default.GIDENTIFY_BUSINESS, _services.default.GIDENTIFY_CONSUMER, _services.default.GOFAC, _services.default.GOOGLE_SEARCH, _services.default.BBB, _services.default.REVIEW_WEBSITE, _services.default.PROHIBITED_PHRASES, _services.default.BUSINESS_LICENSE, _services.default.DNB, _services.default.YELP, _services.default.GOOGLE_REVIEW, _services.default.EXPERIAN],
		'non-profit-rtl': [_services.default.MELISSADATA, _services.default.UPS, _services.default.IRS, _services.default.TINCHECK, _services.default.TMF, _services.default.FEDEDIRECTORY, _services.default.GOOGLE_STREET_VIEW, _services.default.GAUTH, _services.default.GIDENTIFY_BUSINESS, _services.default.GIDENTIFY_CONSUMER, _services.default.GOFAC, _services.default.GOOGLE_SEARCH, _services.default.BBB, _services.default.BUSINESS_LICENSE, _services.default.DNB, _services.default.YELP, _services.default.GOOGLE_REVIEW],
		'non-profit-ecom': [_services.default.MELISSADATA, _services.default.UPS, _services.default.IRS, _services.default.WHOIS, _services.default.TINCHECK, _services.default.TMF, _services.default.FEDEDIRECTORY, _services.default.GOOGLE_STREET_VIEW, _services.default.GAUTH, _services.default.GIDENTIFY_BUSINESS, _services.default.GIDENTIFY_CONSUMER, _services.default.GOFAC, _services.default.GOOGLE_SEARCH, _services.default.BBB, _services.default.REVIEW_WEBSITE, _services.default.PROHIBITED_PHRASES, _services.default.BUSINESS_LICENSE, _services.default.DNB, _services.default.YELP, _services.default.GOOGLE_REVIEW, _services.default.EXPERIAN]
	});
});