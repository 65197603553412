define('checkmate/mixins/models/merchant-business-info-mixin', ['exports', 'ember-data', 'lodash', 'checkmate/enums/countries'], function (exports, _emberData, _lodash, _countries) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var LAST_COMMA_REGEXP = /,(?=[^,]*$)/;
	var ADDRESS_SEPARATOR = ', ';

	var DEFAULT_COUNTRY = _countries.default.find(function (_ref) {
		var name = _ref.name;
		return name === 'UNITED STATES OF AMERICA';
	}).alpha2Code || 'US';

	exports.default = Ember.Mixin.create({
		createdBy: _emberData.default.attr('string'),
		dbaName: _emberData.default.attr('string'),
		dbaAddressLine1: _emberData.default.attr('string'),
		dbaAddressLine2: _emberData.default.attr('string'),
		dbaCity: _emberData.default.attr('string'),
		dbaCountry: _emberData.default.attr('string', { defaultValue: DEFAULT_COUNTRY }),
		dbaEmail: _emberData.default.attr('string'),
		dbaState: _emberData.default.attr('string'),
		dbaZip: _emberData.default.attr('string'),
		fileId: _emberData.default.attr('string'),
		fullAddress: Ember.computed('dbaAddressLine1', 'dbaAddressLine2', 'dbaCity', 'dbaState', 'dbaZip', 'dbaCountry', function () {
			return _lodash.default.compact([this.dbaAddressLine1, this.dbaAddressLine2, this.dbaCity, this.dbaState, this.dbaZip, this.dbaCountry]).join(ADDRESS_SEPARATOR).replace(LAST_COMMA_REGEXP, '');
		})
	});
});