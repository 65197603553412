define('checkmate/helpers/email', ['exports', '@abcum/ember-helpers/helpers/email'], function (exports, _email) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _email.default;
    }
  });
  Object.defineProperty(exports, 'email', {
    enumerable: true,
    get: function () {
      return _email.email;
    }
  });
});