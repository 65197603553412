define('checkmate/helpers/bytes', ['exports', '@abcum/ember-helpers/helpers/bytes'], function (exports, _bytes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bytes.default;
    }
  });
  Object.defineProperty(exports, 'bytes', {
    enumerable: true,
    get: function () {
      return _bytes.bytes;
    }
  });
});