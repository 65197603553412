define('checkmate/helpers/pattern-decimal', ['exports', '@abcum/ember-helpers/helpers/pattern-decimal'], function (exports, _patternDecimal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _patternDecimal.default;
    }
  });
  Object.defineProperty(exports, 'patternDecimal', {
    enumerable: true,
    get: function () {
      return _patternDecimal.patternDecimal;
    }
  });
});