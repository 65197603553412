define('checkmate/components/report-item', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		isDownloading: false,
		data: {},
		downloadCallback: function downloadCallback() {
			this.set('isDownloading', false);
		},

		actions: {
			downloadReport: function downloadReport() {
				var _this = this;

				this.set('isDownloading', true);
				this.sendAction('downloadReport', this.data.downloadUrl, function () {
					return _this.downloadCallback();
				});
			},
			duplicateReport: function duplicateReport() {
				this.sendAction('duplicateReport', this.data.id);
			}
		}
	});
});