define('checkmate/components/app-menu', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var MENU_WIDTH = 240;

	exports.default = Ember.Component.extend({
		router: Ember.inject.service(),
		media: Ember.inject.service(),
		confirmationMessage: 'Would you like to save changes?',
		menuOptions: Ember.computed(function () {
			var isDesktop = this.get('media.isDesktop');

			return {
				width: MENU_WIDTH,
				translucentOverlay: false,
				dismissOnClick: false,
				dismissOnEsc: false,
				gesturesEnabled: false,
				animation: isDesktop ? 'squeeze' : 'push',
				open: isDesktop
			};
		}),
		userName: null,
		brandName: null,
		parentRoute: null,
		showMenu: false,
		sideMenuItems: [],
		actions: {
			goToRoute: function goToRoute(route) {
				this.get('router').transitionTo(route);
			}
		}
	});
});