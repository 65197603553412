define('checkmate/mirage/routes/services', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    server.get('/services');
  };
});