define('checkmate/utils/clearFalsyValues', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = removeEmptyQueryParams;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/**
  * Returns queryParams object.
  * @param {Object} queryParams - queryParams
  * @returns {Object} queryParams with truthy values
  */
	function removeEmptyQueryParams(queryParams) {
		return Object.keys(queryParams).reduce(function (query, key) {
			var value = queryParams[key];

			if (value) {
				if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
					query[key] = removeEmptyQueryParams(value);
				} else {
					query[key] = value;
				}
			}

			return query;
		}, {});
	}
});