define('checkmate/helpers/run', ['exports', '@abcum/ember-helpers/helpers/run'], function (exports, _run) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _run.default;
    }
  });
  Object.defineProperty(exports, 'run', {
    enumerable: true,
    get: function () {
      return _run.run;
    }
  });
});