define('checkmate/mixins/controllers/gofac-validation-mixin', ['exports', 'checkmate/enums/ownerTypes', 'checkmate/enums/services'], function (exports, _ownerTypes, _services) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		noRequiredOwnersError: 'Provide First and Last Name for any Principal or Manager',
		validateGofac: function validateGofac(merchantDetails) {
			var sources = merchantDetails && merchantDetails.sources;
			var isOnlyGofacSelected = sources && sources.includes(_services.default.GOFAC) && sources.length === 1;
			var manager = merchantDetails.get('owners').filterBy('type', _ownerTypes.default.MANAGER).firstObject;
			var principals = merchantDetails.get('owners').filterBy('type', _ownerTypes.default.PRINCIPAL);
			var isManagerAdded = !!manager;
			var areRequiredPrincipalsAdded = principals.length > 0;

			if (isOnlyGofacSelected) {
				if (!isManagerAdded && !areRequiredPrincipalsAdded) {
					return this.noRequiredOwnersError;
				}
			}

			return null;
		}
	});
});