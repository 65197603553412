define('checkmate/helpers/uuid', ['exports', '@abcum/ember-helpers/helpers/uuid'], function (exports, _uuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _uuid.default;
    }
  });
  Object.defineProperty(exports, 'uuid', {
    enumerable: true,
    get: function () {
      return _uuid.uuid;
    }
  });
});