define('checkmate/models/report-status', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		status: _emberData.default.attr('string'),
		serviceStatuses: _emberData.default.attr({ defaultValue: function defaultValue() {
				return [];
			} }),
		error: _emberData.default.attr('string'),
		uploadId: _emberData.default.attr('string'),
		uploadType: _emberData.default.attr('string')
	});
});