define('checkmate/models/merchant-report', ['exports', 'ember-data', 'checkmate/mixins/models/merchant-business-info-mixin', 'checkmate/enums/reportTypes'], function (exports, _emberData, _merchantBusinessInfoMixin, _reportTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend(_merchantBusinessInfoMixin.default, {
		reportType: _reportTypes.default.MERCHANT,
		merchantNumber: _emberData.default.attr('string'),
		firstName: _emberData.default.attr('string'),
		lastName: _emberData.default.attr('string'),
		createdAt: _emberData.default.attr('date'),
		hasReport: _emberData.default.attr('boolean', { defaultValue: false }),
		fullName: Ember.computed('firstName', 'lastName', function () {
			return this.firstName + ' ' + this.lastName;
		})
	});
});