define('checkmate/helpers/sanitize', ['exports', '@abcum/ember-helpers/helpers/sanitize'], function (exports, _sanitize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sanitize.default;
    }
  });
  Object.defineProperty(exports, 'sanitize', {
    enumerable: true,
    get: function () {
      return _sanitize.sanitize;
    }
  });
});