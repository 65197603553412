define('checkmate/mirage/factories/merchant-details', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		fileId: function fileId(i) {
			return i.toString();
		},

		dbaAddressLine1: _faker.default.address.streetAddress,
		dbaAddressLine2: _faker.default.address.secondaryAddress,
		dbaCity: _faker.default.address.city,
		dbaState: _faker.default.address.state,
		dbaZip: function dbaZip() {
			return _faker.default.address.zipCode();
		},
		dbaName: _faker.default.company.companyName,
		createdAt: function createdAt() {
			return _faker.default.date.past().toISOString();
		},

		createdBy: _faker.default.name.findName,
		dbaAccountNumber: function dbaAccountNumber() {
			return _faker.default.random.number();
		},
		dbaCountry: _faker.default.address.country,
		dbaEmail: _faker.default.internet.email,
		ownerEmail: _faker.default.internet.email,
		managerEmail: _faker.default.internet.email,
		dbaPhoneNumber: _faker.default.phone.phoneNumber,
		dbaWebSite: _faker.default.internet.url,
		dunsNumber: function dunsNumber() {
			return _faker.default.random.number();
		},
		federalTaxId: function federalTaxId() {
			return _faker.default.random.number({ min: 100000000, max: 999999999 });
		},
		legalAddressLine1: _faker.default.address.streetAddress,
		legalAddressLine2: _faker.default.address.secondaryAddress,
		legalCity: _faker.default.address.city,
		legalCountry: _faker.default.address.country,
		legalName: _faker.default.company.companyName,
		legalState: _faker.default.address.state,
		legalZip: function legalZip() {
			return _faker.default.address.zipCode();
		},
		merchandiseServiceSold: _faker.default.random.word,
		routingNumber: function routingNumber() {
			return _faker.default.random.number();
		}
	});
});