define('checkmate/mirage/routes/countries', ['exports', 'checkmate/enums/countries'], function (exports, _countries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    server.get('/countries', function () {
      return _countries.default;
    });
  };
});