define('checkmate/routes/reports/custom-reports', ['exports', 'checkmate/mixins/routes/authenticated-route-mixin', 'checkmate/utils/clearFalsyValues'], function (exports, _authenticatedRouteMixin, _clearFalsyValues) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _objectWithoutProperties(obj, keys) {
		var target = {};

		for (var i in obj) {
			if (keys.indexOf(i) >= 0) continue;
			if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
			target[i] = obj[i];
		}

		return target;
	}

	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		model: function model(_ref) {
			var page = _ref.page,
			    pageSize = _ref.pageSize,
			    filters = _objectWithoutProperties(_ref, ['page', 'pageSize']);

			return Ember.RSVP.hash({
				reports: this.store.query('custom-report', (0, _clearFalsyValues.default)({
					meta: {
						page: page,
						pageSize: pageSize
					},
					filters: filters
				}))
			});
		}
	});
});