define('checkmate/mixins/routes/reports/listing', ['exports', 'checkmate/mixins/routes/authenticated-route-mixin', 'checkmate/enums/reportTypes'], function (exports, _authenticatedRouteMixin, _reportTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			var reportType = model.reportType;
			var prevListing = reportType === _reportTypes.default.CUSTOM.toString() ? 'reports.custom-reports' : 'reports.merchant-reports';

			controller.setProperties({
				parentRoute: prevListing
			});
		}
	});
});