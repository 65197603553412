define('checkmate/mirage/routes/users', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/users/self', function () {
			return {
				user: DEFAULT_USER
			};
		});
	};

	var DEFAULT_USER = {
		id: 1,
		firstName: 'FirstName',
		lastName: 'LastName',
		email: 'email@test.com'
	};

	/**
  * Configure endpoints for handling user entity
  *
  * @param {Object} server The mirage server
  */
});