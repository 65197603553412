define('checkmate/mirage/factories/report-status', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var STATUSES = {
		GATHERING_INFO: 'Gathering Info',
		GENERATING_AND_UPLOADING: 'Generating and Uploading Document',
		DONE: 'Done',
		ERROR: 'Error'
	};

	exports.default = _emberCliMirage.Factory.extend({
		status: function status(i) {
			return STATUSES[i % STATUSES.length];
		},

		serviceStatuses: function serviceStatuses() {
			return [];
		},
		error: _faker.default.lorem.sentence
	});
});