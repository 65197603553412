define('checkmate/initializers/ember-data-change-tracker', ['exports', 'ember-data-change-tracker'], function (exports, _emberDataChangeTracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-data-change-tracker',
    after: 'ember-data',
    initialize: _emberDataChangeTracker.initializer
  };
});