define('checkmate/serializers/custom-report', ['exports', 'checkmate/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var PAYLOAD_KEY = 'customReports';

	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		modelNameFromPayloadKey: function modelNameFromPayloadKey() {
			return this._super(PAYLOAD_KEY);
		},

		attrs: {
			merchantDetails: { embedded: 'always' }
		}
	});
});