define('checkmate/helpers/number', ['exports', '@abcum/ember-helpers/helpers/number'], function (exports, _number) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _number.default;
    }
  });
  Object.defineProperty(exports, 'number', {
    enumerable: true,
    get: function () {
      return _number.number;
    }
  });
});