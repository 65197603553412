define('checkmate/components/checkbox-field', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		disabled: false,
		checked: false,
		value: null,
		icon: null,
		id: null,
		classNames: 'checkbox-field',
		classNameBindings: ['disabled:disabled'],
		actions: {
			toggle: function toggle() {
				this.sendAction('toggle', {
					isChecked: !this.get('checked'),
					value: this.get('value')
				});
			}
		}
	});
});