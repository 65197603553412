define('checkmate/components/merchants-table', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		page: null,
		pageSize: null,
		pageCount: null,
		actions: {
			edit: function edit(_ref) {
				var id = _ref.id,
				    reportType = _ref.reportType;

				this.sendAction('edit', { id: id, reportType: reportType });
			},
			gotoPageNumber: function gotoPageNumber(props) {
				this.sendAction('gotoPageNumber', props);
			}
		}
	});
});