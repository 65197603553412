define('checkmate/helpers/pattern-country', ['exports', '@abcum/ember-helpers/helpers/pattern-country'], function (exports, _patternCountry) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _patternCountry.default;
    }
  });
  Object.defineProperty(exports, 'patternCountry', {
    enumerable: true,
    get: function () {
      return _patternCountry.patternCountry;
    }
  });
});