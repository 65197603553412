define('checkmate/helpers/last', ['exports', '@abcum/ember-helpers/helpers/last'], function (exports, _last) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _last.default;
    }
  });
  Object.defineProperty(exports, 'last', {
    enumerable: true,
    get: function () {
      return _last.last;
    }
  });
});