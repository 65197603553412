define('checkmate/services/ajax', ['exports', 'ember-ajax/services/ajax', 'checkmate/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ajax.default.extend({
		host: _environment.default.host,
		session: Ember.inject.service(),
		contentType: 'application/json',
		headers: Ember.computed('session.data', 'session.isAuthenticated', function () {
			var headers = { 'x-client-id': _environment.default.clientId };
			if (this.get('session.isAuthenticated')) {
				headers['auth-token'] = this.get('session.data.authenticated').token;
			}

			return headers;
		}),
		handleResponse: function handleResponse(status) {
			var session = this.get('session');
			// if we get back a 401, then we should invalidate the session
			if (shouldInvalidateSession(status, session)) {
				session.invalidate();
			}

			return this._super.apply(this, arguments);
		}
	});


	/**
  * Checks if session has to be invalidated.
  *
  * @param {Number} status - status code of response
  * @param {Object} session - session service
  * @returns {Boolean} check result
  */
	function shouldInvalidateSession(status, session) {
		return status === 401 && session.get('isAuthenticated');
	}
});