define('checkmate/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'checkmate/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
		session: Ember.inject.service(),
		host: _environment.default.host,
		namespace: _environment.default.namespace,
		headers: {
			'x-client-id': _environment.default.clientId
		},
		authorize: function authorize(xhr) {
			var _get = this.get('session.data.authenticated'),
			    token = _get.token;

			if (Ember.isPresent(token)) {
				xhr.setRequestHeader('auth-token', token);
			}
		}
	});
});