define('checkmate/helpers/pattern-email', ['exports', '@abcum/ember-helpers/helpers/pattern-email'], function (exports, _patternEmail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _patternEmail.default;
    }
  });
  Object.defineProperty(exports, 'patternEmail', {
    enumerable: true,
    get: function () {
      return _patternEmail.patternEmail;
    }
  });
});