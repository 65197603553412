define('checkmate/components/filters/textbox-filter', ['exports', 'checkmate/components/search-field'], function (exports, _searchField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ENTER_CODE = 13;

	exports.default = _searchField.default.extend({
		filter: null,
		actions: {
			onEnterKeydown: function onEnterKeydown(value, event) {
				if (event.keyCode !== ENTER_CODE) {
					return;
				}

				var trimmedValue = value.trim();

				if (this.get('updateFilter')) {
					this.sendAction('updateFilter', this.filter.property, trimmedValue);
				}
			}
		}
	});
});