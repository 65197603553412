define('checkmate/serializers/merchant-report', ['exports', 'checkmate/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var PAYLOAD_KEY = 'merchantReports';

	exports.default = _application.default.extend({
		primaryKey: 'fileId',
		modelNameFromPayloadKey: function modelNameFromPayloadKey() {
			return this._super(PAYLOAD_KEY);
		}
	});
});