define('checkmate/helpers/pattern-currency', ['exports', '@abcum/ember-helpers/helpers/pattern-currency'], function (exports, _patternCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _patternCurrency.default;
    }
  });
  Object.defineProperty(exports, 'patternCurrency', {
    enumerable: true,
    get: function () {
      return _patternCurrency.patternCurrency;
    }
  });
});