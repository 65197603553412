define('checkmate/models/merchant-details', ['exports', 'ember-data', 'lodash', 'ember-cp-validations', 'checkmate/enums/services', 'checkmate/mappings/attribute-services-mapping', 'checkmate/mixins/models/merchant-business-info-mixin', 'checkmate/enums/validationOptions'], function (exports, _emberData, _lodash, _emberCpValidations, _services, _attributeServicesMapping, _merchantBusinessInfoMixin, _validationOptions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var addressLineValidation = _validationOptions.default.addressLineValidation,
	    cityValidation = _validationOptions.default.cityValidation;
	var attr = _emberData.default.attr,
	    hasMany = _emberData.default.hasMany;

	var WEBSITE_CHECK_DEBOUNCE = 500;

	var Validations = (0, _emberCpValidations.buildValidations)({
		createdBy: (0, _emberCpValidations.validator)('presence', true),
		dbaAccountNumber: (0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			isWarning: Ember.computed('model.businessType', function () {
				return !!this.get('model.businessType');
			})
		}),
		dbaAddressLine1: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE }), (0, _emberCpValidations.validator)('format', {
			regex: addressLineValidation.regex,
			message: addressLineValidation.message,
			disabled: Ember.computed('model.dbaAddressLine1', function () {
				return !this.get('model.dbaAddressLine1');
			})
		})],
		dbaCity: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE }), (0, _emberCpValidations.validator)('format', {
			regex: cityValidation.regex,
			message: cityValidation.message,
			disabled: Ember.computed('model.dbaCity', function () {
				return !this.get('model.dbaCity');
			})
		})],
		dbaCountry: (0, _emberCpValidations.validator)('presence', true),
		dbaEmail: (0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }),
		ownerEmail: (0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }),
		managerEmail: (0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }),
		dbaName: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB })],
		dbaPhoneNumber: (0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }),
		dbaState: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE })],
		dbaWebSite: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('website-exists', {
			attributeServicesMapping: _attributeServicesMapping.default,
			debounce: WEBSITE_CHECK_DEBOUNCE
		})],
		dbaZip: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE })],
		federalTaxId: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			isWarning: Ember.computed('model.businessType', function () {
				return !!this.get('model.businessType');
			})
		}), (0, _emberCpValidations.validator)('format', {
			regex: /^\d{9}$/,
			message: 'Federal Tax Id should be 9 digits',
			disabled: Ember.computed('model.sources', function () {
				return !this.get('model.sources') || this.get('model.businessType') || !this.get('model.sources').any(function (source) {
					return [_services.default.TINCHECK, _services.default.TMF].includes(source);
				});
			})
		})],
		legalAddressLine1: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE }), (0, _emberCpValidations.validator)('format', {
			regex: addressLineValidation.regex,
			message: addressLineValidation.message,
			disabled: Ember.computed('model.legalAddressLine1', function () {
				return !this.get('model.legalAddressLine1');
			})
		})],
		legalCity: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE }), (0, _emberCpValidations.validator)('format', {
			regex: cityValidation.regex,
			message: cityValidation.message,
			disabled: Ember.computed('model.legalCity', function () {
				return !this.get('model.legalCity');
			})
		})],
		legalCountry: (0, _emberCpValidations.validator)('presence', true),
		legalName: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE })],
		legalState: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE })],
		legalZip: [(0, _emberCpValidations.validator)('field-value-presence', { attributeServicesMapping: _attributeServicesMapping.default }), (0, _emberCpValidations.validator)('dnb-field-value-presence', { service: _services.default.DNB }), (0, _emberCpValidations.validator)('business-license-field-value-presence', { service: _services.default.BUSINESS_LICENSE })],
		owners: (0, _emberCpValidations.validator)('has-many'),
		routingNumber: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			isWarning: Ember.computed('model.businessType', function () {
				return !!this.get('model.businessType');
			})
		}), (0, _emberCpValidations.validator)('format', {
			regex: /^\d{9}$/,
			message: 'Routing Number should be 9 digits',
			disabled: Ember.computed('model.sources', function () {
				return !this.get('model.sources') || this.get('model.businessType') || !this.get('model.sources').includes(_services.default.FEDEDIRECTORY);
			})
		})],
		sources: (0, _emberCpValidations.validator)('presence', true),
		merchandiseServiceSold: (0, _emberCpValidations.validator)('length', { max: 255 }),
		dunsNumber: [(0, _emberCpValidations.validator)('dnb-field-value-presence'), (0, _emberCpValidations.validator)('format', {
			regex: /^\d{9}$/,
			message: 'DUNS Number should be 9 digits',
			disabled: Ember.computed('model.sources', 'model.dunsNumber', function () {
				return !this.get('model.sources') || !this.get('model.sources').includes(_services.default.DNB) || !this.get('model.dunsNumber');
			})
		})]
	});

	exports.default = _emberData.default.Model.extend(Validations, _merchantBusinessInfoMixin.default, {
		businessType: attr('string'),
		dbaAccountNumber: attr('string'),
		dbaEmail: attr('string'),
		ownerEmail: attr('string'),
		managerEmail: attr('string'),
		dbaPhoneNumber: attr('string'),
		dbaWebSite: attr('string'),
		dunsNumber: attr('string'),
		federalTaxId: attr('string'),
		legalAddressLine1: attr('string'),
		legalAddressLine2: attr('string'),
		legalCity: attr('string'),
		legalCountry: attr('string', { defaultValue: 'USA' }),
		legalName: attr('string'),
		legalState: attr('string'),
		legalZip: attr('string'),
		merchandiseServiceSold: attr({ defaultValue: function defaultValue() {
				return [];
			} }),
		owners: hasMany('owner'),
		routingNumber: attr('string'),
		sources: attr({ defaultValue: function defaultValue() {
				return [];
			} }),
		clearModelProperties: function clearModelProperties() {
			this.setProperties({
				dbaAccountNumber: '',
				dbaAddressLine1: '',
				dbaAddressLine2: '',
				dbaCity: '',
				dbaEmail: '',
				ownerEmail: '',
				managerEmail: '',
				dbaName: '',
				dbaPhoneNumber: '',
				dbaState: '',
				dbaWebSite: '',
				dbaZip: '',
				dunsNumber: '',
				federalTaxId: '',
				legalAddressLine1: '',
				legalAddressLine2: '',
				legalCity: '',
				legalName: '',
				legalState: '',
				legalZip: '',
				merchandiseServiceSold: [],
				routingNumber: '',
				sources: []
			});
		},
		rollbackAttributes: function rollbackAttributes() {
			this._super.apply(this, arguments);
			(0, _lodash.compact)(this.get('owners').toArray()).forEach(function (owner) {
				owner.rollbackAttributes();
			});
		}
	});
});