define('checkmate/helpers/regexp', ['exports', '@abcum/ember-helpers/helpers/regexp'], function (exports, _regexp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _regexp.default;
    }
  });
  Object.defineProperty(exports, 'regexp', {
    enumerable: true,
    get: function () {
      return _regexp.regexp;
    }
  });
});