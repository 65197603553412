define('checkmate/components/merchants-filter', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		showNewReportButton: true,
		actions: {
			updateFilter: function updateFilter(property, value) {
				this.sendAction('updateFilter', property, value);
			}
		}
	});
});