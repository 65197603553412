define('checkmate/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/merchant-details.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/merchant-details.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/merchant.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/merchant.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/owner.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/owner.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/report-status.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/report-status.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/report.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/report.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/service.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/service.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/merchant.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/merchant.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/routes/auth.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/routes/auth.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/routes/countries.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/routes/countries.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/routes/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/routes/index.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/routes/merchants.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/routes/merchants.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/routes/reports.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/routes/reports.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/routes/services.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/routes/services.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/routes/users.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/routes/users.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/merchant-details.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/merchant-details.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/merchant.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/merchant.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/report.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/report.js should pass ESLint\n\n');
  });
});