define('checkmate/components/merchant-form', ['exports', 'checkmate/enums/ownerTypes', 'checkmate/mixins/components/input-filter-unavailable-symbols-mixin'], function (exports, _ownerTypes, _inputFilterUnavailableSymbolsMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed,
	    observer = Ember.observer,
	    $ = Ember.$;


	var MAX_PRINCIPAL_COUNT = 4;
	var MANAGER_OWNER_INDEX = 5;

	exports.default = Ember.Component.extend(_inputFilterUnavailableSymbolsMixin.default, {
		ownerTypes: _ownerTypes.default,
		owners: computed.alias('merchantDetails.owners'),
		principals: computed('owners.[]', function () {
			var principals = this.owners.filterBy('type', _ownerTypes.default.PRINCIPAL);

			return principals.map(function (owner) {
				return Ember.ObjectProxy.create({
					content: owner,
					ownerIndex: principals.indexOf(owner) + 1
				});
			});
		}),
		manager: computed('owners.[]', function () {
			var manager = this.owners.filterBy('type', _ownerTypes.default.MANAGER).firstObject;

			return Ember.ObjectProxy.create({
				content: manager,
				ownerIndex: MANAGER_OWNER_INDEX
			});
		}),
		merchandiseArray: computed('merchandise', function () {
			return this.merchandise ? this.merchandise.split(',').map(function (item) {
				return item.trim();
			}) : [];
		}),
		businessTypeObserver: observer('merchantDetails.businessType', function () {
			if (!this.get('merchantDetails.businessType')) {
				$('.ui.dropdown').dropdown('restore defaults');
			}
		}),
		canAddPrincipal: computed('principals.[]', function () {
			return !this.principals || this.principals.length < MAX_PRINCIPAL_COUNT;
		}),
		actions: {
			generateReport: function generateReport() {
				this.sendAction('generateReport');
			},
			onMerchandiseChange: function onMerchandiseChange() {
				this.sendAction('updateMerchandise', this.merchandiseArray);
			},
			addOwnerForm: function addOwnerForm(ownerType) {
				this.sendAction('addOwner', ownerType);
			},
			removeOwnerForm: function removeOwnerForm(owner) {
				this.sendAction('removeOwner', owner);
			},
			clearMerchantDetailsForm: function clearMerchantDetailsForm() {
				this.sendAction('clearMerchantDetails');
			}
		}
	});
});