define('checkmate/models/report', ['exports', 'ember-data', 'checkmate/config/environment', 'checkmate/enums/uploadTypes'], function (exports, _emberData, _environment, _uploadTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	exports.default = _emberData.default.Model.extend({
		uploadId: _emberData.default.attr('string'),
		uploadType: _emberData.default.attr('string'),
		createdAt: _emberData.default.attr('date'),
		services: _emberData.default.hasMany('service'),
		merchantDetails: _emberData.default.belongsTo('merchant-details', { async: false }),
		downloadUrl: computed('merchantDetails{fileId}', 'uploadId', 'uploadType', 'id', function () {
			var uploadType = this.get('uploadType');
			var url = '';

			if (uploadType === _uploadTypes.default.FILE_CABINET) {
				url = _environment.default.downloadMerchantReportUrl.replace('{fileId}', this.get('merchantDetails.fileId')).replace('{uploadId}', this.uploadId);
			} else if (uploadType === _uploadTypes.default.S3_BUCKET) {
				url = _environment.default.downloadCustomReportUrl.replace('{reportId}', this.get('id'));
			}

			return url;
		}),
		allowedDuplicate: computed('uploadType', function () {
			return this.get('uploadType') === _uploadTypes.default.FILE_CABINET;
		})
	});
});