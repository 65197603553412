define('checkmate/helpers/is-updating', ['exports', '@abcum/ember-helpers/helpers/is-updating'], function (exports, _isUpdating) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isUpdating.default;
    }
  });
  Object.defineProperty(exports, 'isUpdating', {
    enumerable: true,
    get: function () {
      return _isUpdating.isUpdating;
    }
  });
});