define('checkmate/routes/index', ['exports', 'checkmate/mixins/routes/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		beforeModel: function beforeModel() {
			this.transitionTo('reports.merchant-reports');
		}
	});
});