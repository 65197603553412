define('checkmate/components/dropdown-field', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		description: '',
		defaultText: null,
		focused: false
	});
});