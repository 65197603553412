define('checkmate/mixins/controllers/reports/listing', ['exports', 'lodash', 'checkmate/utils/clearFalsyValues'], function (exports, _lodash, _clearFalsyValues) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var MAX_TOTAL_PAGES = 999;
	var QUERY_PARAMS = ['page', 'pageSize', 'searchText', 'createdBy'];

	exports.default = Ember.Mixin.create({
		router: Ember.inject.service(),
		queryParams: QUERY_PARAMS,
		page: 1,
		pageSize: 10,
		searchText: null,
		createdBy: null,
		totalPages: Ember.computed('items.meta.totalPages', function () {
			var totalPages = this.get('items.meta.totalPages');

			return totalPages > MAX_TOTAL_PAGES ? MAX_TOTAL_PAGES : totalPages;
		}),
		filterQuery: Ember.computed.apply(undefined, QUERY_PARAMS.concat([function () {
			return {
				searchText: this.get('searchText'),
				createdBy: this.get('createdBy')
			};
		}])),
		queryObserver: Ember.observer.apply(undefined, QUERY_PARAMS.concat([function () {
			if (this.get('items')) {
				this.updateTable();
			}
		}])),
		getQueryParams: function getQueryParams() {
			return (0, _clearFalsyValues.default)({
				meta: {
					page: this.get('page'),
					pageSize: this.get('pageSize')
				},
				filters: this.get('filterQuery')
			});
		},
		updateTable: function updateTable() {
			var queryParams = this.getQueryParams();
			var oldQueryParams = this.get('items.query');

			if (!(0, _lodash.isEqual)(queryParams, oldQueryParams)) {
				!(0, _lodash.isEqual)(queryParams.filters, oldQueryParams.filters) && this.set('page', 1);
				this.set('items.query', queryParams);
				this.get('items').update();
			}
		},

		actions: {
			updateFilter: function updateFilter(filter, value) {
				this.set(filter, value);
			},
			gotoPageNumber: function gotoPageNumber(props) {
				this.setProperties({
					page: props.page,
					pageSize: props.pageSize
				});
			},
			edit: function edit(_ref) {
				var id = _ref.id,
				    reportType = _ref.reportType;

				this.get('router').transitionTo('reports.edit', id, reportType);
			}
		}
	});
});