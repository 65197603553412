define('checkmate/components/report-history', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			downloadReport: function downloadReport(url, callback) {
				this.sendAction('downloadReport', url, callback);
			},
			duplicateReport: function duplicateReport(id) {
				this.sendAction('duplicateReport', id);
			}
		}
	});
});