define('checkmate/validators/gofac-field-value-presence', ['exports', 'ember-cp-validations/validators/base', 'checkmate/enums/ownerTypes'], function (exports, _base, _ownerTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var GofacFieldValuePresence = _base.default.extend({
		blankFieldError: 'This field can\'t be blank',
		validate: function validate(value, options, model) {
			var _this = this;

			var sources = model.sources || model.merchantDetails && model.merchantDetails.sources;
			var isOnlyGofacSelected = sources && sources.includes(options.service) && sources.length === 1;
			var ownerIndex = model.get('merchantDetails.owners').indexOf(model);
			var isValidPrincipal = options.ownerIndexesToValidate.includes(ownerIndex);
			var isManager = model.get('type') === _ownerTypes.default.MANAGER;
			var shouldValidateOwner = isValidPrincipal || isManager;

			if (isOnlyGofacSelected && shouldValidateOwner) {
				var owners = model.get('merchantDetails.owners');
				var isAnyNameFilled = owners.any(function (owner) {
					var isFirstNameFilled = _this.isValuePresented(owner && owner.get('firstName'));
					var isLastNameFilled = _this.isValuePresented(owner && owner.get('lastName'));

					return isFirstNameFilled && isLastNameFilled;
				});

				if (isAnyNameFilled) {
					return true;
				}

				var isValueValid = this.isValuePresented(value);

				return isValueValid ? true : this.blankFieldError;
			}

			return true;
		},
		isValuePresented: function isValuePresented(value) {
			return !!value && !!value.trim();
		}
	});

	GofacFieldValuePresence.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.merchantDetails.sources', 'model.merchantDetails.owners.@each.firstName', 'model.merchantDetails.owners.@each.lastName'];
		}
	});

	exports.default = GofacFieldValuePresence;
});