define('checkmate/enums/reportStatuses', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		GATHERING_INFO: 'Gathering Info',
		GENERATING_AND_UPLOADING: 'Generating and Uploading Document',
		DONE: 'Done',
		ERROR: 'Error'
	});
});