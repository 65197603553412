define('checkmate/enums/ownerTypes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		MANAGER: 'manager',
		PRINCIPAL: 'principal'
	});
});