define('checkmate/routes/sign-out', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		beforeModel: function beforeModel() {
			return this.get('session').invalidate();
		}
	});
});