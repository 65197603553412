define('checkmate/mixins/controllers/countries-validation-mixin', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		invalidCountryCode: function invalidCountryCode(name) {
			return 'Invalid country code for ' + name;
		},
		validateCountries: function validateCountries(merchantDetails, countries) {
			var dbaCountry = merchantDetails.get('dbaCountry');
			var legalCountry = merchantDetails.get('legalCountry');
			var countryCodes = countries.get('countryCodes');

			if (dbaCountry && !countryCodes.includes(dbaCountry)) {
				return this.invalidCountryCode('dbaCountry');
			}

			if (legalCountry && !countryCodes.includes(legalCountry)) {
				return this.invalidCountryCode('legalCountry');
			}

			return null;
		}
	});
});