define('checkmate/mirage/factories/report', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var UPLOAD_TYPES = ['documentCabinet', 's3Bucket'];

	exports.default = _emberCliMirage.Factory.extend({
		uploadId: _faker.default.random.number(20).toString(),
		uploadType: function uploadType(i) {
			return UPLOAD_TYPES[i % UPLOAD_TYPES.length];
		},
		createdAt: function createdAt() {
			return _faker.default.date.past().toISOString();
		},

		createdBy: _faker.default.name.findName
	});
});