define('checkmate/adapters/user', ['exports', 'checkmate/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForQueryRecord: function urlForQueryRecord(query) {
			if (query.self) {
				delete query.self;

				return this._super.apply(this, arguments) + '/self';
			}

			return this._super.apply(this, arguments);
		}
	});
});