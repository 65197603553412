define('checkmate/helpers/prepend', ['exports', '@abcum/ember-helpers/helpers/prepend'], function (exports, _prepend) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _prepend.default;
    }
  });
  Object.defineProperty(exports, 'prepend', {
    enumerable: true,
    get: function () {
      return _prepend.prepend;
    }
  });
});