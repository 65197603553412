define('checkmate/mirage/serializers/merchant-details', ['exports', 'checkmate/mirage/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		embed: true,
		include: ['owners']
	});
});