define('checkmate/validators/business-license-field-value-presence', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var dbaAddressFields = ['dbaAddressLine1', 'dbaCity', 'dbaState', 'dbaZip'];
	var legalAddressFields = ['legalAddressLine1', 'legalCity', 'legalState', 'legalZip'];
	var ownerAddressFields = ['addressLine', 'city', 'state', 'zipCode'];
	var requiredFieldsIfMDStateIsSelected = ['legalName'].concat(dbaAddressFields, legalAddressFields);
	var requiredFieldsIfMDStateIsNotSelected = ['legalName'].concat(dbaAddressFields, ownerAddressFields);
	var MD_STATE = 'MD';

	var BusinessLicenseFieldValuePresence = _base.default.extend({
		errorMessage: 'This field can\'t be blank',
		validate: function validate(value, options, model, attribute) {
			var sources = model.sources || model.merchantDetails && model.merchantDetails.sources;
			var state = model.dbaState || model.merchantDetails && model.merchantDetails.dbaState;
			var isServiceSelected = sources && sources.includes(options.service);
			var isValidOwnerSelected = !options.ownerType || options.ownerType && model.type === options.ownerType;
			var isContainedRequiredField = isValidOwnerSelected && (state === MD_STATE ? requiredFieldsIfMDStateIsSelected.includes(attribute) : requiredFieldsIfMDStateIsNotSelected.includes(attribute));
			var isInvalidValue = !value || !value.trim();
			var hasError = isServiceSelected && isContainedRequiredField && isInvalidValue;

			if (hasError) {
				return options.message || this.errorMessage;
			}

			return true;
		}
	});

	BusinessLicenseFieldValuePresence.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.sources', 'model.merchantDetails.sources', 'model.dbaState', 'model.merchantDetails.dbaState'];
		}
	});

	exports.default = BusinessLicenseFieldValuePresence;
});