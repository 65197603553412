define('checkmate/components/filters/checkbox-filter', ['exports', 'checkmate/components/checkbox-field'], function (exports, _checkboxField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _checkboxField.default.extend({
		filter: null,
		tagName: 'label',
		classNames: ['checkbox-container'],
		actions: {
			toggle: function toggle(event) {
				this.set('filter.checked', event.target.checked);

				if (this.get('updateFilter')) {
					this.sendAction('updateFilter', this.filter.property, event.target.checked ? this.filter.value : null);
				}
			}
		}
	});
});