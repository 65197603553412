define('checkmate/mirage/factories/service', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var SERVICES = [{ name: 'tmf', displayName: 'TMF' }, { name: 'tincheck', displayName: 'TinCheck' }, { name: 'google-street-view', displayName: 'Google Street View' }, { name: 'dnb', displayName: 'D&B' }, { name: 'giact-gofac', displayName: 'gOFAC' }];

	exports.default = _emberCliMirage.Factory.extend({
		name: function name(i) {
			return SERVICES[i % SERVICES.length].name;
		},
		displayName: function displayName(i) {
			return SERVICES[i % SERVICES.length].displayName;
		}
	});
});