define('checkmate/mirage/routes/index', ['exports', 'checkmate/mirage/routes/auth', 'checkmate/mirage/routes/users', 'checkmate/mirage/routes/merchants', 'checkmate/mirage/routes/reports', 'checkmate/mirage/routes/services', 'checkmate/mirage/routes/countries'], function (exports, _auth, _users, _merchants, _reports, _services, _countries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'auth', {
    enumerable: true,
    get: function () {
      return _auth.default;
    }
  });
  Object.defineProperty(exports, 'users', {
    enumerable: true,
    get: function () {
      return _users.default;
    }
  });
  Object.defineProperty(exports, 'merchants', {
    enumerable: true,
    get: function () {
      return _merchants.default;
    }
  });
  Object.defineProperty(exports, 'reports', {
    enumerable: true,
    get: function () {
      return _reports.default;
    }
  });
  Object.defineProperty(exports, 'services', {
    enumerable: true,
    get: function () {
      return _services.default;
    }
  });
  Object.defineProperty(exports, 'countries', {
    enumerable: true,
    get: function () {
      return _countries.default;
    }
  });
});