define('checkmate/mirage/routes/merchants', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/merchants', function (_ref, _ref2) {
			var merchants = _ref.merchants;
			var queryParams = _ref2.queryParams;
			var _queryParams$metaPag = queryParams['meta[page]'],
			    page = _queryParams$metaPag === undefined ? DEFAULT_PAGE : _queryParams$metaPag,
			    _queryParams$metaPag2 = queryParams['meta[pageSize]'],
			    pageSize = _queryParams$metaPag2 === undefined ? DEFAULT_PAGE_SIZE : _queryParams$metaPag2,
			    searchText = queryParams['filters[searchText]'],
			    createdBy = queryParams['filters[createdBy]'];

			var startIndex = (page - 1) * pageSize;
			var endIndex = page * pageSize;
			var allMerchants = merchants.all().models.filter(function (item) {
				return !searchText || item.dbaName.includes(searchText) || item.fileId === searchText || item.merchantNumber === searchText;
			}).filter(function (item) {
				return !createdBy || item.createdBy === createdBy;
			});

			return {
				merchants: allMerchants.slice(startIndex, endIndex),
				meta: {
					page: page,
					pageSize: pageSize,
					totalPages: Math.ceil(merchants.all().length / pageSize)
				}
			};
		});

		server.get('/merchants/:id/details', function (_ref3, _ref4) {
			var merchantDetails = _ref3.merchantDetails;
			var fileId = _ref4.params.id;

			return merchantDetails.findBy({ fileId: fileId });
		});

		server.get('/merchants/:fileId/documents/:documentId', function () {
			var blob = new Blob(['test'], { type: 'application/pdf' });

			return blob;
		});
	};

	var DEFAULT_PAGE = 1;
	var DEFAULT_PAGE_SIZE = 10;

	/**
  * Configure endpoints for handling merchant entity
  *
  * @param {Object} server The mirage server
  */
});