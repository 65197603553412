define('checkmate/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
		session: Ember.inject.service(),
		user: Ember.inject.service(),
		countries: Ember.inject.service(),
		sessionAuthenticated: function sessionAuthenticated() {
			return this.loadCurrentUser();
		},
		beforeModel: function beforeModel() {
			return this.get('session.isAuthenticated') && this.user.fetch() && this.countries.fetch();
		},
		loadCurrentUser: function loadCurrentUser() {
			var _this = this;

			return this.get('user').fetch().catch(function () {
				return _this.get('session').invalidate();
			});
		},
		loadCountries: function loadCountries() {
			return this.get('countries').fetch();
		}
	});
});