define('checkmate/components/table-pagination', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var PAGE_NUMBERS_TO_DISPLAY_ON_LEFT = 3;
	var PAGE_NUMBERS_TO_DISPLAY_ON_RIGHT = 2;
	var PAGE_NUMBERS_TO_DISPLAY_IN_MIDDLE = 2;
	var PAGE_NUMBERS_TO_DISPLAY_IN_MIDDLE_FOR_MOBILE = 1;

	exports.default = Ember.Component.extend({
		media: Ember.inject.service(),
		classNames: 'pagination-container',
		page: null,
		totalPages: null,
		pageSize: null,
		pageRange: Ember.computed('media.isMobile', function () {
			return this.get('media.isMobile') ? PAGE_NUMBERS_TO_DISPLAY_IN_MIDDLE_FOR_MOBILE : PAGE_NUMBERS_TO_DISPLAY_IN_MIDDLE;
		}),
		showItems: Ember.computed('page', 'pageSize', 'totalPages', function () {
			var currentPage = +this.get('page');
			var totalPages = +this.get('totalPages');
			var pageRange = this.get('pageRange');
			var range = {
				start: currentPage - pageRange,
				end: currentPage + pageRange
			};

			range = getValidRange(range, totalPages, pageRange);

			return [].concat(calculateLeftPartPageNumbers(range, currentPage)).concat(calculateMiddlePartPageNumbers(range, currentPage)).concat(calculateRightPartPageNumbers(range, totalPages, currentPage));
		}),
		actions: {
			changePage: function changePage(page) {
				var totalPages = +this.get('totalPages');

				if (page > totalPages || page < 1) {
					return;
				}

				var props = {
					page: page,
					pageSize: +this.get('pageSize'),
					totalPages: +totalPages
				};

				this.sendAction('gotoPageNumber', props);
			}
		}
	});


	/**
  * Get valid range
  * @param {Object} range The range
  * @param {Number} totalPages The total pages
  * @param {Number} pageRange The page range
  * @returns {{start: *, end: *}} the valid range
  */
	function getValidRange(_ref, totalPages, pageRange) {
		var start = _ref.start,
		    end = _ref.end;

		if (end > totalPages) {
			end = totalPages;
			start = totalPages - pageRange * 2;
			start = start < 1 ? 1 : start;
		}

		if (start <= 1) {
			start = 1;
			end = Math.min(pageRange * 2 + 1, totalPages);
		}

		return {
			start: start,
			end: end
		};
	}

	/**
  * Calculate left part page numbers
  * @param {Object} range The range
  * @param {Number} currentPage The current page
  * @returns {[]} left part page numbers
  */
	function calculateLeftPartPageNumbers(_ref2, currentPage) {
		var start = _ref2.start;

		var pageNumbers = [];

		if (start <= PAGE_NUMBERS_TO_DISPLAY_ON_LEFT) {
			for (var i = 1; i < start; i++) {
				pageNumbers.push(buildPage(i, i === currentPage));
			}
		} else {
			pageNumbers.push(buildPage(1, false));
			pageNumbers.push(buildPage(0, false, '...', true));
		}

		return pageNumbers;
	}

	/**
  * Calculate middle part page numbers
  * @param {Object} range The range
  * @param {Number} currentPage The current page
  * @returns {[]} middle part page numbers
  */
	function calculateMiddlePartPageNumbers(_ref3, currentPage) {
		var start = _ref3.start,
		    end = _ref3.end;

		var pageNumbers = [];

		for (var i = start; i <= end; i++) {
			pageNumbers.push(buildPage(i, i === currentPage));
		}

		return pageNumbers;
	}

	/**
  * Calculate middle part page numbers
  * @param {Object} range The range
  * @param {Number} totalPages The total pages
  * @param {Number} currentPage The current page
  * @returns {[]} right part page numbers
  */
	function calculateRightPartPageNumbers(_ref4, totalPages, currentPage) {
		var end = _ref4.end;

		var pageNumbers = [];

		if (end >= totalPages - PAGE_NUMBERS_TO_DISPLAY_ON_RIGHT) {
			for (var i = end + 1; i <= totalPages; i++) {
				pageNumbers.push(buildPage(i, i === currentPage));
			}
		} else {
			pageNumbers.push(buildPage(0, false, '...', true));
			pageNumbers.push(buildPage(totalPages, false));
		}

		return pageNumbers;
	}
	/**
  * Build page object.
  *
  * @param {Number} index - page index
  * @param {Boolean} isActive - is button active
  * @param {String} displayName - page display name
  * @param {Boolean} isDisabled - is button disabled
  * @returns {Object} page object to bind
  */
	function buildPage(index) {
		var isActive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
		var displayName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
		var isDisabled = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

		return { displayName: displayName || index, page: index, isActive: isActive, isDisabled: isDisabled };
	}
});