define('checkmate/enums/countries', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = [{
		name: 'AFGHANISTAN',
		alpha2Code: 'AF'
	}, {
		name: 'ALAND ISLANDS',
		alpha2Code: 'AX'
	}, {
		name: 'ALBANIA',
		alpha2Code: 'AL'
	}, {
		name: 'ALGERIA',
		alpha2Code: 'DZ'
	}, {
		name: 'AMERICAN SAMOA',
		alpha2Code: 'AS'
	}, {
		name: 'ANDORRA',
		alpha2Code: 'AD'
	}, {
		name: 'ANGOLA',
		alpha2Code: 'AO'
	}, {
		name: 'ANGUILLA',
		alpha2Code: 'AI'
	}, {
		name: 'ANTARCTICA',
		alpha2Code: 'AQ'
	}, {
		name: 'ANTIGUA AND BARBUDA',
		alpha2Code: 'AG'
	}, {
		name: 'ARGENTINA',
		alpha2Code: 'AR'
	}, {
		name: 'ARMENIA',
		alpha2Code: 'AM'
	}, {
		name: 'ARUBA',
		alpha2Code: 'AW'
	}, {
		name: 'AUSTRALIA',
		alpha2Code: 'AU'
	}, {
		name: 'AUSTRIA',
		alpha2Code: 'AT'
	}, {
		name: 'AZERBAIJAN',
		alpha2Code: 'AZ'
	}, {
		name: 'BAHAMAS',
		alpha2Code: 'BS'
	}, {
		name: 'BAHRAIN',
		alpha2Code: 'BH'
	}, {
		name: 'BANGLADESH',
		alpha2Code: 'BD'
	}, {
		name: 'BARBADOS',
		alpha2Code: 'BB'
	}, {
		name: 'BELARUS',
		alpha2Code: 'BY'
	}, {
		name: 'BELGIUM',
		alpha2Code: 'BE'
	}, {
		name: 'BELIZE',
		alpha2Code: 'BZ'
	}, {
		name: 'BENIN',
		alpha2Code: 'BJ'
	}, {
		name: 'BERMUDA',
		alpha2Code: 'BM'
	}, {
		name: 'BHUTAN',
		alpha2Code: 'BT'
	}, {
		name: 'BOLIVIA',
		alpha2Code: 'BO'
	}, {
		name: 'BOSNIA AND HERZEGOVINA',
		alpha2Code: 'BA'
	}, {
		name: 'BOTSWANA',
		alpha2Code: 'BW'
	}, {
		name: 'BOUVET ISLAND',
		alpha2Code: 'BV'
	}, {
		name: 'BRAZIL',
		alpha2Code: 'BR'
	}, {
		name: 'BRITISH INDIAN OCEAN TERRITORY',
		alpha2Code: 'IO'
	}, {
		name: 'BRITISH VIRGIN ISLANDS',
		alpha2Code: 'VG'
	}, {
		name: 'BRUNEI DARUSSALAM',
		alpha2Code: 'BN'
	}, {
		name: 'BULGARIA',
		alpha2Code: 'BG'
	}, {
		name: 'BURKINA FASO',
		alpha2Code: 'BF'
	}, {
		name: 'BURUNDI',
		alpha2Code: 'BI'
	}, {
		name: 'CAMBODIA',
		alpha2Code: 'KH'
	}, {
		name: 'CAMEROON',
		alpha2Code: 'CM'
	}, {
		name: 'CANADA',
		alpha2Code: 'CA'
	}, {
		name: 'CAPE VERDE',
		alpha2Code: 'CV'
	}, {
		name: 'CAYMAN ISLANDS',
		alpha2Code: 'KY'
	}, {
		name: 'CENTRAL AFRICAN REPUBLIC',
		alpha2Code: 'CF'
	}, {
		name: 'CHAD',
		alpha2Code: 'TD'
	}, {
		name: 'CHILE',
		alpha2Code: 'CL'
	}, {
		name: 'CHINA',
		alpha2Code: 'CN'
	}, {
		name: 'CHRISTMAS ISLAND',
		alpha2Code: 'CX'
	}, {
		name: 'COCOS (KEELING) ISLANDS',
		alpha2Code: 'CC'
	}, {
		name: 'COLOMBIA',
		alpha2Code: 'CO'
	}, {
		name: 'COMOROS',
		alpha2Code: 'KM'
	}, {
		name: 'CONGO (BRAZZAVILLE)',
		alpha2Code: 'CG'
	}, {
		name: 'CONGO, (KINSHASA)',
		alpha2Code: 'CD'
	}, {
		name: 'COOK ISLANDS',
		alpha2Code: 'CK'
	}, {
		name: 'COSTA RICA',
		alpha2Code: 'CR'
	}, {
		name: "CÔTE D'IVOIRE",
		alpha2Code: 'CI'
	}, {
		name: 'CROATIA',
		alpha2Code: 'HR'
	}, {
		name: 'CUBA',
		alpha2Code: 'CU'
	}, {
		name: 'CYPRUS',
		alpha2Code: 'CY'
	}, {
		name: 'CZECH REPUBLIC',
		alpha2Code: 'CZ'
	}, {
		name: 'DENMARK',
		alpha2Code: 'DK'
	}, {
		name: 'DJIBOUTI',
		alpha2Code: 'DJ'
	}, {
		name: 'DOMINICA',
		alpha2Code: 'DM'
	}, {
		name: 'DOMINICAN REPUBLIC',
		alpha2Code: 'DO'
	}, {
		name: 'ECUADOR',
		alpha2Code: 'EC'
	}, {
		name: 'EGYPT',
		alpha2Code: 'EG'
	}, {
		name: 'EL SALVADOR',
		alpha2Code: 'SV'
	}, {
		name: 'EQUATORIAL GUINEA',
		alpha2Code: 'GQ'
	}, {
		name: 'ERITREA',
		alpha2Code: 'ER'
	}, {
		name: 'ESTONIA',
		alpha2Code: 'EE'
	}, {
		name: 'ETHIOPIA',
		alpha2Code: 'ET'
	}, {
		name: 'FALKLAND ISLANDS (MALVINAS)',
		alpha2Code: 'FK'
	}, {
		name: 'FAROE ISLANDS',
		alpha2Code: 'FO'
	}, {
		name: 'FIJI',
		alpha2Code: 'FJ'
	}, {
		name: 'FINLAND',
		alpha2Code: 'FI'
	}, {
		name: 'FRANCE',
		alpha2Code: 'FR'
	}, {
		name: 'FRENCH GUIANA',
		alpha2Code: 'GF'
	}, {
		name: 'FRENCH POLYNESIA',
		alpha2Code: 'PF'
	}, {
		name: 'FRENCH SOUTHERN TERRITORIES',
		alpha2Code: 'TF'
	}, {
		name: 'GABON',
		alpha2Code: 'GA'
	}, {
		name: 'GAMBIA',
		alpha2Code: 'GM'
	}, {
		name: 'GEORGIA',
		alpha2Code: 'GE'
	}, {
		name: 'GERMANY',
		alpha2Code: 'DE'
	}, {
		name: 'GHANA',
		alpha2Code: 'GH'
	}, {
		name: 'GIBRALTAR',
		alpha2Code: 'GI'
	}, {
		name: 'GREECE',
		alpha2Code: 'GR'
	}, {
		name: 'GREENLAND',
		alpha2Code: 'GL'
	}, {
		name: 'GRENADA',
		alpha2Code: 'GD'
	}, {
		name: 'GUADELOUPE',
		alpha2Code: 'GP'
	}, {
		name: 'GUAM',
		alpha2Code: 'GU'
	}, {
		name: 'GUATEMALA',
		alpha2Code: 'GT'
	}, {
		name: 'GUERNSEY',
		alpha2Code: 'GG'
	}, {
		name: 'GUINEA',
		alpha2Code: 'GN'
	}, {
		name: 'GUINEA-BISSAU',
		alpha2Code: 'GW'
	}, {
		name: 'GUYANA',
		alpha2Code: 'GY'
	}, {
		name: 'HAITI',
		alpha2Code: 'HT'
	}, {
		name: 'HEARD AND MCDONALD ISLANDS',
		alpha2Code: 'HM'
	}, {
		name: 'HOLY SEE (VATICAN CITY STATE)',
		alpha2Code: 'VA'
	}, {
		name: 'HONDURAS',
		alpha2Code: 'HN'
	}, {
		name: 'HONG KONG, SAR CHINA',
		alpha2Code: 'HK'
	}, {
		name: 'HUNGARY',
		alpha2Code: 'HU'
	}, {
		name: 'ICELAND',
		alpha2Code: 'IS'
	}, {
		name: 'INDIA',
		alpha2Code: 'IN'
	}, {
		name: 'INDONESIA',
		alpha2Code: 'ID'
	}, {
		name: 'IRAN, ISLAMIC REPUBLIC OF',
		alpha2Code: 'IR'
	}, {
		name: 'IRAQ',
		alpha2Code: 'IQ'
	}, {
		name: 'IRELAND',
		alpha2Code: 'IE'
	}, {
		name: 'ISLE OF MAN',
		alpha2Code: 'IM'
	}, {
		name: 'ISRAEL',
		alpha2Code: 'IL'
	}, {
		name: 'ITALY',
		alpha2Code: 'IT'
	}, {
		name: 'JAMAICA',
		alpha2Code: 'JM'
	}, {
		name: 'JAPAN',
		alpha2Code: 'JP'
	}, {
		name: 'JERSEY',
		alpha2Code: 'JE'
	}, {
		name: 'JORDAN',
		alpha2Code: 'JO'
	}, {
		name: 'KAZAKHSTAN',
		alpha2Code: 'KZ'
	}, {
		name: 'KENYA',
		alpha2Code: 'KE'
	}, {
		name: 'KIRIBATI',
		alpha2Code: 'KI'
	}, {
		name: 'KOREA (NORTH)',
		alpha2Code: 'KP'
	}, {
		name: 'KOREA (SOUTH)',
		alpha2Code: 'KR'
	}, {
		name: 'KUWAIT',
		alpha2Code: 'KW'
	}, {
		name: 'KYRGYZSTAN',
		alpha2Code: 'KG'
	}, {
		name: 'LAO PDR',
		alpha2Code: 'LA'
	}, {
		name: 'LATVIA',
		alpha2Code: 'LV'
	}, {
		name: 'LEBANON',
		alpha2Code: 'LB'
	}, {
		name: 'LESOTHO',
		alpha2Code: 'LS'
	}, {
		name: 'LIBERIA',
		alpha2Code: 'LR'
	}, {
		name: 'LIBYA',
		alpha2Code: 'LY'
	}, {
		name: 'LIECHTENSTEIN',
		alpha2Code: 'LI'
	}, {
		name: 'LITHUANIA',
		alpha2Code: 'LT'
	}, {
		name: 'LUXEMBOURG',
		alpha2Code: 'LU'
	}, {
		name: 'MACAO, SAR CHINA',
		alpha2Code: 'MO'
	}, {
		name: 'MACEDONIA, REPUBLIC OF',
		alpha2Code: 'MK'
	}, {
		name: 'MADAGASCAR',
		alpha2Code: 'MG'
	}, {
		name: 'MALAWI',
		alpha2Code: 'MW'
	}, {
		name: 'MALAYSIA',
		alpha2Code: 'MY'
	}, {
		name: 'MALDIVES',
		alpha2Code: 'MV'
	}, {
		name: 'MALI',
		alpha2Code: 'ML'
	}, {
		name: 'MALTA',
		alpha2Code: 'MT'
	}, {
		name: 'MARSHALL ISLANDS',
		alpha2Code: 'MH'
	}, {
		name: 'MARTINIQUE',
		alpha2Code: 'MQ'
	}, {
		name: 'MAURITANIA',
		alpha2Code: 'MR'
	}, {
		name: 'MAURITIUS',
		alpha2Code: 'MU'
	}, {
		name: 'MAYOTTE',
		alpha2Code: 'YT'
	}, {
		name: 'MEXICO',
		alpha2Code: 'MX'
	}, {
		name: 'MICRONESIA, FEDERATED STATES OF',
		alpha2Code: 'FM'
	}, {
		name: 'MOLDOVA',
		alpha2Code: 'MD'
	}, {
		name: 'MONACO',
		alpha2Code: 'MC'
	}, {
		name: 'MONGOLIA',
		alpha2Code: 'MN'
	}, {
		name: 'MONTENEGRO',
		alpha2Code: 'ME'
	}, {
		name: 'MONTSERRAT',
		alpha2Code: 'MS'
	}, {
		name: 'MOROCCO',
		alpha2Code: 'MA'
	}, {
		name: 'MOZAMBIQUE',
		alpha2Code: 'MZ'
	}, {
		name: 'MYANMAR',
		alpha2Code: 'MM'
	}, {
		name: 'NAMIBIA',
		alpha2Code: 'NA'
	}, {
		name: 'NAURU',
		alpha2Code: 'NR'
	}, {
		name: 'NEPAL',
		alpha2Code: 'NP'
	}, {
		name: 'NETHERLANDS',
		alpha2Code: 'NL'
	}, {
		name: 'NETHERLANDS ANTILLES',
		alpha2Code: 'AN'
	}, {
		name: 'NEW CALEDONIA',
		alpha2Code: 'NC'
	}, {
		name: 'NEW ZEALAND',
		alpha2Code: 'NZ'
	}, {
		name: 'NICARAGUA',
		alpha2Code: 'NI'
	}, {
		name: 'NIGER',
		alpha2Code: 'NE'
	}, {
		name: 'NIGERIA',
		alpha2Code: 'NG'
	}, {
		name: 'NIUE',
		alpha2Code: 'NU'
	}, {
		name: 'NORFOLK ISLAND',
		alpha2Code: 'NF'
	}, {
		name: 'NORTHERN MARIANA ISLANDS',
		alpha2Code: 'MP'
	}, {
		name: 'NORWAY',
		alpha2Code: 'NO'
	}, {
		name: 'OMAN',
		alpha2Code: 'OM'
	}, {
		name: 'PAKISTAN',
		alpha2Code: 'PK'
	}, {
		name: 'PALAU',
		alpha2Code: 'PW'
	}, {
		name: 'PALESTINIAN TERRITORY',
		alpha2Code: 'PS'
	}, {
		name: 'PANAMA',
		alpha2Code: 'PA'
	}, {
		name: 'PAPUA NEW GUINEA',
		alpha2Code: 'PG'
	}, {
		name: 'PARAGUAY',
		alpha2Code: 'PY'
	}, {
		name: 'PERU',
		alpha2Code: 'PE'
	}, {
		name: 'PHILIPPINES',
		alpha2Code: 'PH'
	}, {
		name: 'PITCAIRN',
		alpha2Code: 'PN'
	}, {
		name: 'POLAND',
		alpha2Code: 'PL'
	}, {
		name: 'PORTUGAL',
		alpha2Code: 'PT'
	}, {
		name: 'PUERTO RICO',
		alpha2Code: 'PR'
	}, {
		name: 'QATAR',
		alpha2Code: 'QA'
	}, {
		name: 'RÉUNION',
		alpha2Code: 'RE'
	}, {
		name: 'ROMANIA',
		alpha2Code: 'RO'
	}, {
		name: 'RUSSIAN FEDERATION',
		alpha2Code: 'RU'
	}, {
		name: 'RWANDA',
		alpha2Code: 'RW'
	}, {
		name: 'SAINT HELENA',
		alpha2Code: 'SH'
	}, {
		name: 'SAINT KITTS AND NEVIS',
		alpha2Code: 'KN'
	}, {
		name: 'SAINT LUCIA',
		alpha2Code: 'LC'
	}, {
		name: 'SAINT PIERRE AND MIQUELON',
		alpha2Code: 'PM'
	}, {
		name: 'SAINT VINCENT AND GRENADINES',
		alpha2Code: 'VC'
	}, {
		name: 'SAINT-BARTHÉLEMY',
		alpha2Code: 'BL'
	}, {
		name: 'SAINT-MARTIN (FRENCH PART)',
		alpha2Code: 'MF'
	}, {
		name: 'SAMOA',
		alpha2Code: 'WS'
	}, {
		name: 'SAN MARINO',
		alpha2Code: 'SM'
	}, {
		name: 'SAO TOME AND PRINCIPE',
		alpha2Code: 'ST'
	}, {
		name: 'SAUDI ARABIA',
		alpha2Code: 'SA'
	}, {
		name: 'SENEGAL',
		alpha2Code: 'SN'
	}, {
		name: 'SERBIA',
		alpha2Code: 'RS'
	}, {
		name: 'SEYCHELLES',
		alpha2Code: 'SC'
	}, {
		name: 'SIERRA LEONE',
		alpha2Code: 'SL'
	}, {
		name: 'SINGAPORE',
		alpha2Code: 'SG'
	}, {
		name: 'SLOVAKIA',
		alpha2Code: 'SK'
	}, {
		name: 'SLOVENIA',
		alpha2Code: 'SI'
	}, {
		name: 'SOLOMON ISLANDS',
		alpha2Code: 'SB'
	}, {
		name: 'SOMALIA',
		alpha2Code: 'SO'
	}, {
		name: 'SOUTH AFRICA',
		alpha2Code: 'ZA'
	}, {
		name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
		alpha2Code: 'GS'
	}, {
		name: 'SOUTH SUDAN',
		alpha2Code: 'SS'
	}, {
		name: 'SPAIN',
		alpha2Code: 'ES'
	}, {
		name: 'SRI LANKA',
		alpha2Code: 'LK'
	}, {
		name: 'SUDAN',
		alpha2Code: 'SD'
	}, {
		name: 'SURINAME',
		alpha2Code: 'SR'
	}, {
		name: 'SVALBARD AND JAN MAYEN ISLANDS',
		alpha2Code: 'SJ'
	}, {
		name: 'SWAZILAND',
		alpha2Code: 'SZ'
	}, {
		name: 'SWEDEN',
		alpha2Code: 'SE'
	}, {
		name: 'SWITZERLAND',
		alpha2Code: 'CH'
	}, {
		name: 'SYRIAN ARAB REPUBLIC (SYRIA)',
		alpha2Code: 'SY'
	}, {
		name: 'TAIWAN, REPUBLIC OF CHINA',
		alpha2Code: 'TW'
	}, {
		name: 'TAJIKISTAN',
		alpha2Code: 'TJ'
	}, {
		name: 'TANZANIA, UNITED REPUBLIC OF',
		alpha2Code: 'TZ'
	}, {
		name: 'THAILAND',
		alpha2Code: 'TH'
	}, {
		name: 'TIMOR-LESTE',
		alpha2Code: 'TL'
	}, {
		name: 'TOGO',
		alpha2Code: 'TG'
	}, {
		name: 'TOKELAU',
		alpha2Code: 'TK'
	}, {
		name: 'TONGA',
		alpha2Code: 'TO'
	}, {
		name: 'TRINIDAD AND TOBAGO',
		alpha2Code: 'TT'
	}, {
		name: 'TUNISIA',
		alpha2Code: 'TN'
	}, {
		name: 'TURKEY',
		alpha2Code: 'TR'
	}, {
		name: 'TURKMENISTAN',
		alpha2Code: 'TM'
	}, {
		name: 'TURKS AND CAICOS ISLANDS',
		alpha2Code: 'TC'
	}, {
		name: 'TUVALU',
		alpha2Code: 'TV'
	}, {
		name: 'UGANDA',
		alpha2Code: 'UG'
	}, {
		name: 'UKRAINE',
		alpha2Code: 'UA'
	}, {
		name: 'UNITED ARAB EMIRATES',
		alpha2Code: 'AE'
	}, {
		name: 'UNITED KINGDOM',
		alpha2Code: 'GB'
	}, {
		name: 'UNITED STATES OF AMERICA',
		alpha2Code: 'US'
	}, {
		name: 'URUGUAY',
		alpha2Code: 'UY'
	}, {
		name: 'US MINOR OUTLYING ISLANDS',
		alpha2Code: 'UM'
	}, {
		name: 'UZBEKISTAN',
		alpha2Code: 'UZ'
	}, {
		name: 'VANUATU',
		alpha2Code: 'VU'
	}, {
		name: 'VENEZUELA (BOLIVARIAN REPUBLIC)',
		alpha2Code: 'VE'
	}, {
		name: 'VIET NAM',
		alpha2Code: 'VN'
	}, {
		name: 'VIRGIN ISLANDS, US',
		alpha2Code: 'VI'
	}, {
		name: 'WALLIS AND FUTUNA ISLANDS',
		alpha2Code: 'WF'
	}, {
		name: 'WESTERN SAHARA',
		alpha2Code: 'EH'
	}, {
		name: 'YEMEN',
		alpha2Code: 'YE'
	}, {
		name: 'ZAMBIA',
		alpha2Code: 'ZM'
	}, {
		name: 'ZIMBABWE',
		alpha2Code: 'ZW'
	}];
});