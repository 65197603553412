define('checkmate/mappings/attribute-services-mapping', ['exports', 'checkmate/enums/services'], function (exports, _services) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		dbaName: [_services.default.BBB, _services.default.YELP, _services.default.TMF, _services.default.GOOGLE_STREET_VIEW, _services.default.GOOGLE_REVIEW, _services.default.GOOGLE_SEARCH, _services.default.MELISSADATA, _services.default.PROHIBITED_PHRASES, _services.default.WHOIS, _services.default.REVIEW_WEBSITE, _services.default.GOFAC],
		dbaAddressLine1: [_services.default.YELP, _services.default.GOOGLE_REVIEW, _services.default.UPS, _services.default.GOOGLE_STREET_VIEW, _services.default.MELISSADATA, _services.default.TMF],
		dbaCity: [_services.default.BBB, _services.default.YELP, _services.default.GOOGLE_REVIEW, _services.default.MELISSADATA, _services.default.TMF],
		dbaState: [_services.default.BBB, _services.default.YELP, _services.default.GOOGLE_REVIEW, _services.default.MELISSADATA, _services.default.TMF],
		dbaZip: [_services.default.BBB, _services.default.YELP, _services.default.GOOGLE_REVIEW, _services.default.UPS, _services.default.GOOGLE_STREET_VIEW, _services.default.TMF],
		dbaAccountNumber: [_services.default.GAUTH],
		dbaWebSite: [_services.default.PROHIBITED_PHRASES, _services.default.WHOIS, _services.default.REVIEW_WEBSITE],
		routingNumber: [_services.default.FEDEDIRECTORY, _services.default.GAUTH],
		legalName: [_services.default.TINCHECK, _services.default.TMF, _services.default.GIDENTIFY_BUSINESS, _services.default.GAUTH],
		legalAddressLine1: [_services.default.GIDENTIFY_BUSINESS],
		legalCity: [_services.default.GIDENTIFY_BUSINESS],
		legalState: [_services.default.GIDENTIFY_BUSINESS],
		legalZip: [_services.default.GIDENTIFY_BUSINESS],
		federalTaxId: [_services.default.TMF, _services.default.TINCHECK, _services.default.MELISSADATA, _services.default.IRS],
		firstName: [_services.default.TMF],
		lastName: [_services.default.TMF]
	});
});