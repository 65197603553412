define('checkmate/helpers/match-by', ['exports', '@abcum/ember-helpers/helpers/match-by'], function (exports, _matchBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _matchBy.default;
    }
  });
  Object.defineProperty(exports, 'matchBy', {
    enumerable: true,
    get: function () {
      return _matchBy.matchBy;
    }
  });
});