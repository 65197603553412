define('checkmate/helpers/find', ['exports', '@abcum/ember-helpers/helpers/find'], function (exports, _find) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _find.default;
    }
  });
  Object.defineProperty(exports, 'find', {
    enumerable: true,
    get: function () {
      return _find.find;
    }
  });
});