define('checkmate/controllers/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service(),
		user: Ember.inject.service(),
		self: {},
		userName: Ember.computed.alias('user.userName'),
		brandName: 'Shift4',
		sideMenuItems: Ember.computed('currentRouteName', function () {
			var menuItems = [[this.buildDefaultLink({ displayName: 'Merchant Reports' }, 'reports.merchant-reports'), this.buildDefaultLink({ displayName: 'Custom Reports' }, 'reports.custom-reports')], [{ displayName: 'Sign Out', params: ['sign-out'] }]];

			return menuItems.map(function (block) {
				return block.compact();
			});
		}),
		appController: Ember.inject.controller('application'),
		currentRouteName: Ember.computed.reads('appController.currentRouteName'),
		currentController: Ember.computed('currentRouteName', function () {
			var owner = Ember.getOwner(this);
			var currentRouteName = this.get('currentRouteName');
			var route = owner.lookup('route:' + currentRouteName);

			return route && route.controller;
		}),
		parentRoute: Ember.computed('currentController', function () {
			return this.get('currentController.parentRoute');
		}),
		appMenuTopText: Ember.computed.alias('currentController.appMenuTopText'),
		parentOptions: Ember.computed('currentController', function () {
			return this.get('currentController.parentOptions');
		}),
		buildDefaultLink: function buildDefaultLink(presetValue, routeName) {
			return Object.assign(presetValue, {
				activeRoute: this.get('parentRoute') === routeName ? this.currentRouteName : routeName,
				params: [routeName, {
					isQueryParams: true,
					values: {
						page: 1,
						searchText: null,
						createdBy: null
					}
				}]
			});
		}
	});
});