define('checkmate/helpers/inside', ['exports', '@abcum/ember-helpers/helpers/inside'], function (exports, _inside) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inside.default;
    }
  });
  Object.defineProperty(exports, 'inside', {
    enumerable: true,
    get: function () {
      return _inside.inside;
    }
  });
});