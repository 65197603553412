define('checkmate/validators/field-value-presence', ['exports', 'ember-cp-validations/validators/base', 'lodash'], function (exports, _base, _lodash) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var SERVICE_LIST_SEPARATOR = ', ';

	var FieldValuePresence = _base.default.extend({
		store: Ember.inject.service(),
		errorMessage: 'This field can\'t be blank',
		warningMessage: 'If field left blank the following services will be skipped: $serviceList',
		validate: function validate(value, options, model, attribute) {
			var sources = model.sources || model.merchantDetails && model.merchantDetails.sources;
			var isServiceSelected = sources && options.attributeServicesMapping && options.attributeServicesMapping[attribute] && sources.some(function (source) {
				return options.attributeServicesMapping[attribute].includes(source);
			});
			var isValidOwnerSelected = !options.ownerType || options.ownerType && model.type === options.ownerType;
			var isInvalidValue = !value || !value.trim();
			var hasWarning = isServiceSelected && this.isWarning && isInvalidValue;
			var hasError = isServiceSelected && isValidOwnerSelected && isInvalidValue;

			if (hasWarning) {
				var serviceList = this.getDependentServices(options, attribute);

				return this.warningMessage.replace('$serviceList', serviceList);
			}

			if (hasError) {
				return options.message || this.errorMessage;
			}

			return true;
		},
		getDependentServices: function getDependentServices(options, attribute) {
			var services = this.get('store').peekAll('service');
			var dependentServices = _lodash.default.compact(options.attributeServicesMapping[attribute].map(function (serviceName) {
				var serviceItem = services.filterBy('name', serviceName).firstObject;

				return serviceItem ? serviceItem.get('displayName') : null;
			}));

			return dependentServices.join(SERVICE_LIST_SEPARATOR);
		}
	});

	FieldValuePresence.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.sources', 'model.merchantDetails.sources'];
		}
	});

	exports.default = FieldValuePresence;
});