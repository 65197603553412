define('checkmate/models/owner', ['exports', 'ember-data', 'ember-cp-validations', 'lodash', 'checkmate/mappings/attribute-services-mapping', 'checkmate/enums/services', 'checkmate/enums/ownerTypes', 'checkmate/enums/validationOptions'], function (exports, _emberData, _emberCpValidations, _lodash, _attributeServicesMapping, _services, _ownerTypes, _validationOptions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var addressLineValidation = _validationOptions.default.addressLineValidation,
	    cityValidation = _validationOptions.default.cityValidation;
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;

	var GIDENTIFY_CONSUMER_OWNER_INDEXES_TO_VALIDATE = [0, 1];
	var GOFAC_OWNER_INDEXES_TO_VALIDATE = [2, 3];
	var OWNER_NAME_SEPARATOR = ' ';

	var Validations = (0, _emberCpValidations.buildValidations)({
		addressLine: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('business-license-field-value-presence', {
			service: _services.default.BUSINESS_LICENSE,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('gidentify-consumer-field-value-presence', {
			service: _services.default.GIDENTIFY_CONSUMER,
			ownerIndexesToValidate: GIDENTIFY_CONSUMER_OWNER_INDEXES_TO_VALIDATE
		}), (0, _emberCpValidations.validator)('experian-field-value-presence', {
			service: _services.default.EXPERIAN
		}), (0, _emberCpValidations.validator)('format', {
			regex: addressLineValidation.regex,
			message: addressLineValidation.message,
			disabled: Ember.computed('model.addressLine', function () {
				return !this.get('model.addressLine');
			})
		})],
		city: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('business-license-field-value-presence', {
			service: _services.default.BUSINESS_LICENSE,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('gidentify-consumer-field-value-presence', {
			service: _services.default.GIDENTIFY_CONSUMER,
			ownerIndexesToValidate: GIDENTIFY_CONSUMER_OWNER_INDEXES_TO_VALIDATE
		}), (0, _emberCpValidations.validator)('experian-field-value-presence', {
			service: _services.default.EXPERIAN
		}), (0, _emberCpValidations.validator)('format', {
			regex: cityValidation.regex,
			message: cityValidation.message,
			disabled: Ember.computed('model.city', function () {
				return !this.get('model.city');
			})
		})],
		firstName: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('dnb-field-value-presence', {
			service: _services.default.DNB,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('gidentify-consumer-field-value-presence', {
			service: _services.default.GIDENTIFY_CONSUMER,
			ownerIndexesToValidate: GIDENTIFY_CONSUMER_OWNER_INDEXES_TO_VALIDATE
		}), (0, _emberCpValidations.validator)('gofac-field-value-presence', {
			service: _services.default.GOFAC,
			ownerIndexesToValidate: GOFAC_OWNER_INDEXES_TO_VALIDATE
		}), (0, _emberCpValidations.validator)('experian-field-value-presence', {
			service: _services.default.EXPERIAN
		})],
		lastName: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('dnb-field-value-presence', {
			service: _services.default.DNB,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('gidentify-consumer-field-value-presence', {
			service: _services.default.GIDENTIFY_CONSUMER,
			ownerIndexesToValidate: GIDENTIFY_CONSUMER_OWNER_INDEXES_TO_VALIDATE
		}), (0, _emberCpValidations.validator)('gofac-field-value-presence', {
			service: _services.default.GOFAC,
			ownerIndexesToValidate: GOFAC_OWNER_INDEXES_TO_VALIDATE
		}), (0, _emberCpValidations.validator)('experian-field-value-presence', {
			service: _services.default.EXPERIAN
		})],
		phoneNumber: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('experian-field-value-presence', {
			service: _services.default.EXPERIAN
		})],
		ssn: (0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			ownerType: _ownerTypes.default.PRINCIPAL
		}),
		state: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('business-license-field-value-presence', {
			service: _services.default.BUSINESS_LICENSE,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('gidentify-consumer-field-value-presence', {
			service: _services.default.GIDENTIFY_CONSUMER,
			ownerIndexesToValidate: GIDENTIFY_CONSUMER_OWNER_INDEXES_TO_VALIDATE
		}), (0, _emberCpValidations.validator)('experian-field-value-presence', {
			service: _services.default.EXPERIAN
		})],
		zipCode: [(0, _emberCpValidations.validator)('field-value-presence', {
			attributeServicesMapping: _attributeServicesMapping.default,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('business-license-field-value-presence', {
			service: _services.default.BUSINESS_LICENSE,
			ownerType: _ownerTypes.default.PRINCIPAL
		}), (0, _emberCpValidations.validator)('gidentify-consumer-field-value-presence', {
			service: _services.default.GIDENTIFY_CONSUMER,
			ownerIndexesToValidate: GIDENTIFY_CONSUMER_OWNER_INDEXES_TO_VALIDATE
		}), (0, _emberCpValidations.validator)('experian-field-value-presence', {
			service: _services.default.EXPERIAN
		})],
		country: (0, _emberCpValidations.validator)('presence', true),
		type: (0, _emberCpValidations.validator)('presence', true)
	});

	exports.default = _emberData.default.Model.extend(Validations, {
		addressLine: attr('string'),
		city: attr('string'),
		firstName: attr('string'),
		lastName: attr('string'),
		phoneNumber: attr('string'),
		ssn: attr('string'),
		state: attr('string'),
		zipCode: attr('string'),
		country: attr('string', { defaultValue: 'USA' }),
		type: attr('string'),
		merchantDetails: belongsTo('merchant-details', { async: false }),
		fullName: Ember.computed('firstName', 'lastName', function () {
			return (0, _lodash.compact)([this.firstName, this.lastName]).join(OWNER_NAME_SEPARATOR);
		})
	});
});