define('checkmate/helpers/slug', ['exports', '@abcum/ember-helpers/helpers/slug'], function (exports, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _slug.default;
    }
  });
  Object.defineProperty(exports, 'slug', {
    enumerable: true,
    get: function () {
      return _slug.slug;
    }
  });
});