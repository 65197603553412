define('checkmate/validators/dnb-field-value-presence', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var requiredFieldsIfDunsNumberIsNotEmpty = ['dbaName', 'dunsNumber'];
	var requiredFieldsIfDunsNumberIsEmpty = ['dbaName', 'dbaAddressLine1', 'dbaCity', 'dbaState', 'dbaZip', 'legalName', 'legalAddressLine1', 'legalCity', 'legalState', 'legalZip', 'firstName', 'lastName'];

	var DnbFieldValuePresence = _base.default.extend({
		errorMessage: 'This field can\'t be blank',
		validate: function validate(value, options, model, attribute) {
			var sources = model.sources || model.merchantDetails && model.merchantDetails.sources;
			var dunsNumber = model.dunsNumber || model.merchantDetails && model.merchantDetails.dunsNumber;
			var isServiceSelected = sources && sources.includes(options.service);
			var isValidOwnerSelected = !options.ownerType || options.ownerType && model.type === options.ownerType;
			var isContainedRequiredField = isValidOwnerSelected && (dunsNumber ? requiredFieldsIfDunsNumberIsNotEmpty.includes(attribute) : requiredFieldsIfDunsNumberIsEmpty.includes(attribute));
			var isInvalidValue = !value || !value.trim();
			var hasError = isServiceSelected && isContainedRequiredField && isInvalidValue;

			if (hasError) {
				return options.message || this.errorMessage;
			}

			return true;
		}
	});

	DnbFieldValuePresence.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.sources', 'model.merchantDetails.sources', 'model.dunsNumber', 'model.merchantDetails.dunsNumber'];
		}
	});

	exports.default = DnbFieldValuePresence;
});