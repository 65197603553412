define('checkmate/helpers/check', ['exports', '@abcum/ember-helpers/helpers/check'], function (exports, _check) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _check.default;
    }
  });
  Object.defineProperty(exports, 'check', {
    enumerable: true,
    get: function () {
      return _check.check;
    }
  });
});