define('checkmate/mirage/config', ['exports', 'checkmate/config/environment', 'checkmate/mirage/routes'], function (exports, _environment, _routes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		this.passthrough('/write-coverage');
		this.namespace = _environment.default.namespace;

		_routes.default.auth(this);
		_routes.default.users(this);
		_routes.default.merchants(this);
		_routes.default.reports(this);
		_routes.default.services(this);
	};
});