define("checkmate/enums/reportTypes", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		MERCHANT: 1,
		CUSTOM: 2
	});
});