define('checkmate/services/user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		userName: Ember.computed('userData.{firstName,lastName}', function () {
			return this.get('userData.firstName') + ' ' + this.get('userData.lastName');
		}),
		fetch: function fetch() {
			var _this = this;

			return this.store.queryRecord('user', { self: true }).then(function (user) {
				_this.set('userData', user);
			});
		}
	});
});