define('checkmate/routes/sign-in', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		oauth: Ember.inject.service(),
		beforeModel: function beforeModel(transition) {
			var _this = this;

			this.handleGoogleCallback();

			if (this.get('session.isAuthenticated')) {
				return this.transitionTo(this.oauth.defaultRoute);
			}

			return this.oauth.authenticate(transition).then(function (route) {
				transition.abort();
				_this.transitionTo(route);
			}).catch(function () {});
		},
		handleGoogleCallback: function handleGoogleCallback() {
			var parsedUrl = new URL(window.location.href);
			var googleCode = parsedUrl.searchParams.get('code');
			var googleState = parsedUrl.searchParams.get('state');

			if (googleCode && googleState) {
				var url = atob(googleState);
				var hasParams = url.includes('?');
				var separator = hasParams ? '&' : '?';

				window.location = '' + url + separator + 'code=' + googleCode;
			}
		},
		model: function model() {
			return this.oauth.model();
		}
	});
});