define('checkmate/components/service-status-table', ['exports', 'checkmate/enums/serviceStatuses', 'checkmate/enums/reportStatuses'], function (exports, _serviceStatuses, _reportStatuses) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	exports.default = Ember.Component.extend({
		getServiceStatusClassName: function getServiceStatusClassName(status) {
			switch (status) {
				case _serviceStatuses.default.DONE:
					return 'status-done';
				case _serviceStatuses.default.IN_PROGRESS:
					return 'status-in-progress';
				case _serviceStatuses.default.ERROR:
					return 'status-error';
				default:
					return 'status-pending';
			}
		},
		getUploadingStatusClassName: function getUploadingStatusClassName(status) {
			switch (status) {
				case _reportStatuses.default.DONE:
					return 'status-done';
				case _reportStatuses.default.GENERATING_AND_UPLOADING:
					return 'status-in-progress';
				case _reportStatuses.default.ERROR:
					return 'status-error';
				case _reportStatuses.default.GATHERING_INFO:
				default:
					return 'status-pending';
			}
		},
		getUploadingStatusName: function getUploadingStatusName(status) {
			switch (status) {
				case _reportStatuses.default.DONE:
					return _serviceStatuses.default.DONE;
				case _reportStatuses.default.GENERATING_AND_UPLOADING:
					return _serviceStatuses.default.IN_PROGRESS;
				case _reportStatuses.default.ERROR:
					return _serviceStatuses.default.ERROR;
				case _reportStatuses.default.GATHERING_INFO:
				default:
					return _serviceStatuses.default.PENDING;
			}
		},

		serviceStatuses: computed('reportStatus.serviceStatuses.[]', function () {
			var _this = this;

			if (!this.get('reportStatus.serviceStatuses')) {
				return [];
			}

			return this.get('reportStatus.serviceStatuses').map(function (serviceStatus) {
				return {
					status: serviceStatus.status,
					serviceName: serviceStatus.displayName,
					className: _this.getServiceStatusClassName(serviceStatus.status)
				};
			});
		}),
		uploadingStatus: computed('reportStatus.status', function () {
			return {
				status: this.getUploadingStatusName(this.get('reportStatus.status')),
				className: this.getUploadingStatusClassName(this.get('reportStatus.status'))
			};
		})
	});
});