define('checkmate/components/owner-form', ['exports', 'checkmate/mixins/components/input-filter-unavailable-symbols-mixin'], function (exports, _inputFilterUnavailableSymbolsMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	exports.default = Ember.Component.extend(_inputFilterUnavailableSymbolsMixin.default, {
		owner: null,
		ownerValidations: computed.alias('owner.validations.attrs'),
		actions: {
			removeForm: function removeForm() {
				this.removeForm(this.owner);
			}
		}
	});
});