define('checkmate/mirage/routes/auth', ['exports', 'checkmate/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/auth/url', function () {
			return { GOOGLE_AUTH_ENDPOINT: GOOGLE_AUTH_ENDPOINT };
		});
		server.post('/auth/login', function () {
			return { token: _environment.default.authResponse.token };
		});
		server.post('/auth/logout', function () {
			return { status: LOGOUT_STATUS };
		});
	};

	var GOOGLE_AUTH_ENDPOINT = 'test google auth endpoint';
	var LOGOUT_STATUS = 'ok';

	/**
  * Configure endpoints for handling google auth
  *
  * @param {Object} server The mirage server
  */
});